import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'

import { validaIndicacaoError, validaIndicacaoSuccess } from '../actions'

export default function* getValidaIndicacao({ payload }: AnyAction) {
  const { cpf } = yield* select((state: ApplicationState) => state.simulacao)

  const codigo = yield* select((state: ApplicationState) => state.indicacaoProposta.codigoIndicacao)
  try {
    const request = {
      cpfUtilizador: cpf,
      codigo,
      campanha: process.env.REACT_APP_CAMPANHA_INDIQUE_E_GANHE || '',
    }

    const { data } = yield services.getValidaIndicacao(request)

    if (data.valido) {
      yield put(validaIndicacaoSuccess())
      if (payload.success instanceof Function) {
        payload.success()
      }
    } else {
      yield put(validaIndicacaoError())
    }
  } catch (e) {
    yield put(validaIndicacaoError())
    if (payload.error instanceof Function) {
      payload.error()
    }
  }
}
