import { Reducer } from 'redux'

import { DadosTokenTypes, DadosTokenState } from './types'

const INITIAL_STATE: DadosTokenState = {
  telefone: '',
  email: '',
  averbacaoAutorizada: false,
}

const reducer: Reducer<DadosTokenState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DadosTokenTypes.SET_TELEFONE:
      return { ...state, telefone: action.payload }
    case DadosTokenTypes.SET_EMAIL:
      return { ...state, email: action.payload }
    case DadosTokenTypes.SET_AVERBACAO_AUTORIZADA:
      return { ...state, averbacaoAutorizada: action.payload }
    case DadosTokenTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
