import { Reducer } from 'redux'

import { UserState, UserTypes } from './types'

const INITIAL_STATE: UserState = {
  cpfUser: '',
  telefoneUser: '',
  emailUser: '',
  nomeUser: '',
  conta: '',
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.SET_CPF:
      return { ...state, cpfUser: action.payload }
    case UserTypes.SET_TELEFONE:
      return { ...state, telefoneUser: action.payload }
    case UserTypes.SET_EMAIL:
      return { ...state, emailUser: action.payload }
    case UserTypes.SET_NOME:
      return { ...state, nomeUser: action.payload }
    case UserTypes.SET_CONTA:
      return { ...state, conta: action.payload }

    case UserTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
