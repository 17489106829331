import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { P } from '@interco/cp-react-ui-lib'
import { InterUIBottomSheet, InterUIButton } from '@interco/inter-ui-react-lib'
import { clearError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'

const ErrorMessage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    showError = false,
    message = 'Ocorreu um erro inesperado, tente novamente.',
    title = 'Ocorreu um erro',
    code,
    actionTitle = 'Entendi',
    actionCallback,
    block,
    historyBack,
    functionCallback,
  } = useSelector((state: ApplicationState) => state.ui.error)
  const [open, setOpen] = useState(showError)

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  return (
    <InterUIBottomSheet
      onHide={
        !block
          ? () => {
              setOpen(false)
              setTimeout(() => {
                dispatch(clearError())
              }, 600)

              if (historyBack) {
                history.goBack()
              }
            }
          : () => null
      }
      title={title || ''}
      toggle={open}
    >
      <P margin={code ? 'unset' : '0 0 15px 0;'}>{message}</P>
      {code && <P margin="0 0 15px 0;">{`COD (${code})`}</P>}

      <InterUIButton
        onClick={() => {
          setOpen(false)
          setTimeout(() => {
            dispatch(clearError())
            if (actionCallback instanceof Function) {
              dispatch(actionCallback())
            } else if (functionCallback instanceof Function) {
              functionCallback()
            }
          }, 600)
        }}
      >
        {actionTitle}
      </InterUIButton>
    </InterUIBottomSheet>
  )
}

export default ErrorMessage
