import styled from 'styled-components'

import { Page } from '@interco/cp-react-ui-lib'

const RootPage = styled(Page)<{
  isApp: boolean
  backgroundColor?: string
  hasProgressStep?: boolean
  fullPage?: boolean
}>`
  background-color: var(--neutral-theme);

  height: 100vh;

  max-height: ${(props) => props.isApp && props.hasProgressStep && `calc(100vh - 41px)`};
  max-height: ${(props) => !props.isApp && props.hasProgressStep && `calc(100vh - 188px)`};
  max-height: ${(props) => !props.isApp && !props.hasProgressStep && `calc(100vh - 148px)`};

  @media (min-width: 768px) {
    max-height: ${(props) => !props.isApp && props.hasProgressStep && `calc(100vh - 225px)`};
    max-height: ${(props) => !props.isApp && !props.hasProgressStep && `calc(100vh - 185px)`};
  }
  @media (min-width: 991px) {
    max-height: ${(props) => !props.isApp && props.hasProgressStep && `calc(100vh - 154px)`};
    max-height: ${(props) => !props.isApp && !props.hasProgressStep && `calc(100vh - 112px)`};
  }

  & > div:first-child {
    margin-top: ${(props) => !props.isApp && !props.hasProgressStep && `0px`};
    margin-bottom: ${(props) => !props.isApp && !props.hasProgressStep && `0px`};
  }
`

export default RootPage
