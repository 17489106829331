export enum Colors {
  GRAY700 = 'var(--gray700)',
  GRAY600 = 'var(--gray600)',
  GRAY500 = 'var(--gray500)',
  GRAY400 = 'var(--gray400)',
  GRAY300 = 'var(--gray300)',
  GRAY200 = 'var(--gray200)',
  GRAY100 = 'var(--gray100)',
  ORANGE500 = 'var(--primary500)',
  PRIMARY500 = 'var(--primary500)',
  PRIMARY400 = 'var(--primary400)',
  PRIMARY300 = 'var(--primary300)',
  PRIMARY200 = 'var(--primary200)',
  PRIMARY100 = 'var(--primary100)',
  SUCCESS100 = 'var(--success100)',
  SUCCESS500 = 'var(--success500)',
  ALERT100 = 'var(--alert100)',
  ALERT500 = 'var(--alert500)',
  ERROR100 = 'var(--error100)',
  ERROR500 = 'var(--error500)',
  WHITE = 'var(--neutral-theme)',
  RED = 'var(--error500)',
  BLACK = 'var(--gray500)',
}
