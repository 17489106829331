import { addDays } from 'date-fns'

import { RealizarAverbacaoSiapeRequest } from '@services/postRealizarAverbacaoSiape'
import { AssinaturaState } from '@store/assinatura'
import { PropostaState } from '@store/proposta'
import { DateFormat } from '@utils/format'
import { convertStringValueToNumber } from '@utils/masks'
import { CapturaState } from '@store/captura'

export const buildRealizarAverbacaoSiape = (
  capturaState: CapturaState,
  assinaturaState: AssinaturaState,
  propostaState: PropostaState,
): RealizarAverbacaoSiapeRequest => {
  const cet = String(capturaState.documentos?.valorCET)
  const dtValidadeAnuencia = DateFormat(addDays(new Date(), 10), 'dd/MM/yyyy')
  const iof = String(convertStringValueToNumber(assinaturaState.resumo.resumo?.valorIOF))
  const nrContrato = String(propostaState.criacaoProposta?.numeroProposta)
  const pzDesconto = String(assinaturaState.resumo.resumo?.quantidadeDeParcelas)
  const txJurosMensal = String(
    convertStringValueToNumber(assinaturaState.resumo.resumo?.taxaNominalContrato),
  )
  const vlBruto = String(
    assinaturaState.resumo.resumo?.quantidadeDeParcelas *
      convertStringValueToNumber(assinaturaState.resumo.resumo?.valorParcela),
  )
  const vlDesconto = String(convertStringValueToNumber(assinaturaState.resumo.resumo?.valorParcela))
  const vlLiquido = String(
    convertStringValueToNumber(assinaturaState.resumo.resumo?.valorSolicitado),
  )

  const objetoAverbacao = {
    cet,
    dtValidadeAnuencia,
    iof,
    nrContrato,
    pzDesconto,
    txJurosMensal,
    vlBruto,
    vlDesconto,
    vlLiquido,
  }

  return objetoAverbacao
}
