import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'

import { editarLead, setLoadingEditarLead } from '../actions'

export default function* postEditarLeadSaga({ payload }: AnyAction) {
  const { cpf } = yield* select((state: ApplicationState) => state.simulacao)
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
  try {
    yield put(setLoadingEditarLead(true))
    const { tipo = 'Crédito Consignado Público' } = payload
    yield services.postEditarLead(
      {
        numeroProposta: payload?.numeroProposta ? String(payload?.numeroProposta) : '',
        cpf,
        tipo,
        situacao: payload?.situacao || '',
      },
      newSimulatorApi,
    )
    yield put(setLoadingEditarLead(false))
  } catch (error) {
    yield put(setLoadingEditarLead(false))
    yield put(
      showError({
        title: 'Erro ao enviar informações',
        message: 'Ocorreu um erro ao enviar informações.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => editarLead(payload),
      }),
    )
  } finally {
    yield put(setLoadingEditarLead(false))
    if (payload.callback instanceof Function) {
      payload.callback()
    }
  }
}
