import { action, Action } from 'typesafe-actions'

import { CriarIndicacaoPayload, IndicacaoPayload, IndicacaoTypes } from './types'

export const setCodigoIndicacao = (payload: string): Action =>
  action(IndicacaoTypes.SET_CODIGO_INDICACAO, payload)

export const validaIndicacaoRequest = (payload: IndicacaoPayload): Action =>
  action(IndicacaoTypes.VALIDA_INDICACAO_REQUEST, payload)

export const validaIndicacaoError = (): Action => action(IndicacaoTypes.VALIDA_INDICACAO_ERROR)

export const validaIndicacaoSuccess = (): Action => action(IndicacaoTypes.VALIDA_INDICACAO_SUCCESS)

export const criarIndicacaoProposta = (payload: CriarIndicacaoPayload): Action =>
  action(IndicacaoTypes.CRIAR_INDICACAO_PROPOSTA_SAGA, payload)

export const indicacaoDone = (payload: boolean): Action =>
  action(IndicacaoTypes.INDICACAO_DONE, payload)

export const setCodigoAplicado = (payload: boolean): Action =>
  action(IndicacaoTypes.SET_CODIGO_APLICADO, payload)
