import { action, Action } from 'typesafe-actions'

import { Banco, DadosBancariosTypes, SalvarPropostaPayload, ValidaPropostaPayload } from './types'

export const bancosRequest = (): Action => action(DadosBancariosTypes.BANCOS_REQUEST)

export const bancosSuccess = (payload: Banco[] | null): Action =>
  action(DadosBancariosTypes.BANCOS_SUCCESS, payload)

export const bancosLoading = (): Action => action(DadosBancariosTypes.BANCOS_LOADING)

export const bancosError = (): Action => action(DadosBancariosTypes.BANCOS_ERROR)

export const setBancoSelecionado = (payload: string | number | undefined): Action =>
  action(DadosBancariosTypes.SET_BANCO_SELECIONADO, payload)

export const setTipoConta = (payload: string | number | undefined): Action =>
  action(DadosBancariosTypes.SET_TIPO_CONTA, payload)

export const setTipoCreditoInss = (payload: string | number | undefined): Action =>
  action(DadosBancariosTypes.SET_TIPO_CREDITO_INSS, payload)

export const setAgencia = (payload: string): Action =>
  action(DadosBancariosTypes.SET_AGENCIA, payload)

export const setConta = (payload: string): Action => action(DadosBancariosTypes.SET_CONTA, payload)

export const setDigito = (payload: string): Action =>
  action(DadosBancariosTypes.SET_DIGITO, payload)

export const salvarPropostaRequest = (payload: SalvarPropostaPayload): Action =>
  action(DadosBancariosTypes.SALVAR_PROPOSTA_REQUEST, payload)

export const salvarPropostaSuccess = (): Action =>
  action(DadosBancariosTypes.SALVAR_PROPOSTA_SUCCESS)

export const salvarPropostaError = (): Action => action(DadosBancariosTypes.SALVAR_PROPOSTA_ERROR)

export const dadosBancariosInssRequest = (): Action =>
  action(DadosBancariosTypes.DADOS_BANCARIOS_INSS_REQUEST)

export const dadosBancariosInssSuccess = (): Action =>
  action(DadosBancariosTypes.DADOS_BANCARIOS_INSS_SUCCESS)

export const dadosBancariosInssError = (): Action =>
  action(DadosBancariosTypes.DADOS_BANCARIOS_INSS_ERROR)

export const infoValida = (payload?: ValidaPropostaPayload): Action =>
  action(DadosBancariosTypes.INFO_VALIDA, payload)

export const setContato = (payload: boolean): Action =>
  action(DadosBancariosTypes.SET_CONTATO, payload)

export const setValidaLoading = (payload: boolean): Action =>
  action(DadosBancariosTypes.SET_VALIDA_LOADING, payload)
