import { Reducer } from 'redux'

import { ResumoTypes, ResumoState } from './types'

const INITIAL_STATE: ResumoState = {
  resumo: {
    numeroParcelas: 0,
    valorParcela: '',
    taxa: '',
    cet: '',
    percentualIOF: '',
    valorIOF: '',
    valorLiberado: '23.000,00',
    total: '',
    primeiroDesconto: '',
    ultimoDesconto: '',
    taxaNominalContrato: '',
    taxaNominalAnual: '',
    jurosContratadosPercentual: '',
    jurosContratados: '',
    valorTotalDestinadoPagamentoSeguro: '',
    percentualPropostaSeguroPrestamista: '',
    tarifaCadastro: '',
    taxaTarifaCadastro: '',
    valorSolicitado: '',
    valorSolicitadoPercentual: '',
    valorOperacao: '',
    valorDoMutuo: '',
    atributosCetMensal: '',
    atributosCetAnual: '',
    dataNascimento: '',
    nomeMae: '',
    valorTotalASerPago: '',
    quantidadeDeParcelas: 0,
    matricula: '',
    tipoLiberacao: '',
    jurosCapitalizados: true,
    estipulante: '',
    possuiSeguro: false,
    dataEmissao: '',
    enderecoResidencial: '',
    cidadeResidencial: '',
    estadoResidencial: '',
    bairroResidencial: '',
    numeroResidencial: '',
    nomeCliente: '',
    cpf: '',
    nomeConjuge: '',
    banco: '',
    agencia: '',
    conta: '',
    nomePai: '',
    cidadeNaturalidade: '',
    estadoNaturalidade: '',
    estadoCivil: '',
    nacionalidade: '',
    documentoIdentificacao: '',
    taxaEfetivaMensal: '',
    taxaEfetivaAnual: '',
    orgao: '',
    complementoResidencial: '',
    cepResidencial: '',
    cepResidencialComMascara: '',
    dddCelular: '',
    telefoneCelular: '',
    codigoContratoOriginalPortabilidade: '',
    bancoPortadoDescricao: '',
    contratosRefin: [],
    saldoDevedorTotalRefin: '',
  },
  resumoError: false,
}

const reducer: Reducer<ResumoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ResumoTypes.DADOS_RESUMO_SUCCESS:
      return { ...state, resumo: action.payload, ccbError: false }
    case ResumoTypes.DADOS_RESUMO_ERROR:
      return { ...state, resumoError: true }
    case ResumoTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
