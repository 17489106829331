import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'

import { criarIndicacaoProposta } from '../actions'

export default function* postCriarIndicacaoProposta({ payload }: AnyAction) {
  try {
    const codigoExterno = yield* select(
      (state: ApplicationState) => state.proposta.criacaoProposta.numeroProposta,
    )

    const nomeUtilizador = yield* select((state: ApplicationState) => state.simulacao.nome)
    const cpfCnpjUtilizador = yield* select((state: ApplicationState) => state.simulacao.cpf)
    const codigo = yield* select(
      (state: ApplicationState) => state.indicacaoProposta.codigoIndicacao,
    )

    yield services.postPropostaIndicacao({
      cpfCnpjUtilizador,
      nomeUtilizador: String(nomeUtilizador),
      codigoExterno: String(codigoExterno),
      codigo,
      valorPagamentoCalculado: 0,
    })
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao enviar informações',
        message: 'Ocorreu um erro ao enviar informações.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => criarIndicacaoProposta(payload),
      }),
    )
  } finally {
    if (payload.callback instanceof Function) {
      payload.callback()
    }
  }
}
