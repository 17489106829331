import styled from 'styled-components'

export const Grid = styled.div<{
  margin?: string
  height?: number | string
}>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${(props) => (props.height ? props.height : '100%')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
`
