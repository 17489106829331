import { BaseServices, HttpMethod } from '@services/base'

export default (benefitNumber: string) => {
  const pathname = `${process.env.REACT_APP_GET_DADOS_BANCARIOS_INSS_BFF}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const headers = {
    benefitNumber,
  }
  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
