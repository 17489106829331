import { action, Action } from 'typesafe-actions'

import { Escolaridade, EscolaridadeTypes, Profissao } from './types'

export const setEscolaridadeSelecionada = (payload: string | number | undefined): Action =>
  action(EscolaridadeTypes.SET_ESCOLARIDADE_SELECIONADA, payload)

export const escolaridadesRequest = (): Action => action(EscolaridadeTypes.ESCOLARIDADES_REQUEST)

export const escolaridadesSuccess = (payload: Escolaridade[] | null): Action =>
  action(EscolaridadeTypes.ESCOLARIDADES_SUCCESS, payload)
export const escolaridadesLoading = (): Action => action(EscolaridadeTypes.ESCOLARIDADES_LOADING)
export const escolaridadesError = (): Action => action(EscolaridadeTypes.ESCOLARIDADES_ERROR)

export const setProfissaoSelecionada = (payload: string | number | undefined): Action =>
  action(EscolaridadeTypes.SET_PROFISSAO_SELECIONADA, payload)

export const profissoesRequest = (): Action => action(EscolaridadeTypes.PROFISSOES_REQUEST)

export const profissoesSuccess = (payload: Profissao[] | null): Action =>
  action(EscolaridadeTypes.PROFISSOES_SUCCESS, payload)
export const profissoesLoading = (): Action => action(EscolaridadeTypes.PROFISSOES_LOADING)
export const profissoesError = (): Action => action(EscolaridadeTypes.PROFISSOES_ERROR)

export const setPatrimonios = (payload: string | number | undefined): Action =>
  action(EscolaridadeTypes.SET_PATRIMONIOS, payload)

export const setKeyPatrimonio = (payload: string | number | undefined): Action =>
  action(EscolaridadeTypes.SET_KEY_PATRIMONIO, payload)

export const setValuePatrimonio = (payload: string | number | undefined): Action =>
  action(EscolaridadeTypes.SET_VALUE_PATRIMONIO, payload)

export const setIsPep = (payload: boolean): Action => action(EscolaridadeTypes.SET_IS_PEP, payload)

export const setPepType = (payload: string): Action =>
  action(EscolaridadeTypes.SET_PEP_TYPE, payload)

export const setUsPerson = (payload: boolean): Action =>
  action(EscolaridadeTypes.SET_US_PERSON, payload)

export const setTinNumber = (payload: string): Action =>
  action(EscolaridadeTypes.SET_TIN_NUMBER, payload)

export const setEstadoCivil = (payload: string): Action =>
  action(EscolaridadeTypes.SET_ESTADO_CIVIL, payload)
