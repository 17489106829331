import { BancosAPI } from '@store/proposta/dadosBancarios/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi ? process.env.REACT_APP_BANKS_BFF : process.env.REACT_APP_BANKS
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<BancosAPI>({ pathname, method, host })
  return response
}
