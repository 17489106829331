import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default async (biometricToken: string, accessToken?: string) => {
  const pathname = BridgeService.isBrowser()
    ? `portal/bff/v1/${process.env.REACT_APP_BIOMETRICS_VALIDATE}`
    : process.env.REACT_APP_BIOMETRICS_VALIDATE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data: { biometricToken },
  })
  return response
}
