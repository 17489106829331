import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import { setCpf } from '@store/simulacao/actions'
import { setEmail, setNome, setTelefone } from '@store/captura/documentos/actions'
import { setNumeroProposta } from '@store/proposta/criacaoProposta/actions'

import { getBiometriaDados, setLoadingBiometrics } from '../actions'

interface Props {
  payload: {
    accessToken?: string
    callback: () => void
  }
  type: string
}

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}

export default function* getBiometriaDataSaga({ payload }: Props) {
  const { biometricToken } = yield* select((s: ApplicationState) => s.captura.videoSelfie)
  const { accessToken } = yield* select((s: ApplicationState) => s.ui.navigation)
  yield put(setLoadingBiometrics(true))
  try {
    const { data } = yield* call(
      services.getDadosBiometria,
      biometricToken,
      payload.accessToken || accessToken,
    )
    yield put(setCpf(String(data.document)))
    yield put(setNome(String(data.name)))
    yield put(setNumeroProposta(String(data.proposalCode)))
    yield put(setEmail(String(data.email)))
    yield put(setTelefone(String(data.cellPhone)))
    yield put(setLoadingBiometrics(false))
    yield payload.callback()
  } catch (error) {
    yield put(setLoadingBiometrics(false))
    yield put(
      showError({
        title: 'Ocorreu um erro ao carregar dados para biometria',
        message: 'Por favor tente novamente.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => getBiometriaDados(payload),
        block: true,
      }),
    )
  }
}
