import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { showError } from '@store/ui/error/actions'
import services from '@services'
import { ApplicationState } from '@store/types'
import { setIdIteracao, setTipoDocumento } from '@store/proposta/criacaoProposta/actions'

import { hideLoading, postCapturaDocumentos, showLoading } from '../actions'

interface Props {
  noRemoveLoader: boolean
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
    block: boolean
  }
  type: string
}

export default function* postCaptureDocuments({
  noRemoveLoader,
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => postCapturaDocumentos(),
    actionTitle: 'Tentar Novamente',
    block: true,
  },
}: Props) {
  try {
    const propostaInfo = yield* select((state: ApplicationState) => state.proposta.criacaoProposta)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    // Caso ja tenha carregado os documentos, não busca novamente para evitar erro de criação de integração com GEDIN
    if (!propostaInfo?.tipoDocumento) {
      yield put(showLoading())
      const appState = yield* select((state: ApplicationState) => state)
      const { data } = yield services.postCaptureDocuments(
        {
          cpf: appState.simulacao.cpf,
          numeroProposta: String(appState.proposta.criacaoProposta.numeroProposta),
        },
        newSimulatorApi,
      )

      if (data) {
        const tipoDocumento = data.enviados.concat(data.pendentes)
        yield put(setTipoDocumento(tipoDocumento))
        yield put(setIdIteracao(data.idInteracao))
      }

      if (!noRemoveLoader) {
        yield put(hideLoading())
      }
    }
    return true
  } catch (error) {
    yield put(hideLoading())
    yield put(showError(actionCallbackError))
    return false
  }
}
