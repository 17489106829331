import { useCallback } from 'react'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const useGoogleRecaptchaToken = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const getToken = useCallback(async () => {
    if (executeRecaptcha) {
      return executeRecaptcha('validacao_simulador')
    }
    return ''
  }, [executeRecaptcha])

  return { getToken }
}

export default useGoogleRecaptchaToken
