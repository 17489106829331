import { jsPDF as JsPDF } from 'jspdf'

import { Anexo } from '@store/captura/fotoDocumentos/types'

/**
 * Gera um PDF com as imagens anexadas.
 * @param anexos
 * @returns Blob
 */
export default class GeneratePdf {
  static generate(anexos: Anexo[]) {
    const anexosImagens = anexos.filter((anexo) => anexo.anexo.tipoExtensao !== 'application/pdf')
    const jsPdf = this.build(anexosImagens)
    // Descomentar para testes, faz o download direto no navegador
    // jsPdf?.save('pdf_gerado.pdf')

    if (jsPdf) {
      return new Blob([jsPdf.output('blob')], {
        type: 'application/pdf',
      })
    }
    return ''
  }

  static build(anexos: Anexo[]) {
    let jsPdf = null
    const fileType = 'JPEG'

    // Iteracao a cada anexo
    for (let i = 0; i < anexos.length; i += 1) {
      const blob = anexos[i].anexo.blobs

      for (let j = 0; j < blob.length; j += 1) {
        const page = blob[j]
        const orientation = page.width > page.height ? 'l' : 'p'
        const format = [page.width, page.height]

        if (!jsPdf) {
          jsPdf = new JsPDF({
            unit: 'px',
            hotfixes: ['px_scaling'],
            orientation,
            format,
          })
        } else {
          jsPdf.addPage(format, orientation)
        }

        jsPdf.addImage(page.data, fileType, 0, 0, page.width, page.height, undefined, 'NONE')
      }
    }
    return jsPdf
  }
}
