import { Reducer } from 'redux'

import { ErrorState, ErrorTypes } from './types'

const INITIAL_STATE: ErrorState = {
  showError: false,
  message: undefined,
  title: undefined,
  actionCallback: undefined,
  actionTitle: undefined,
  block: false,
  code: undefined,
}

const reducer: Reducer<ErrorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ErrorTypes.SHOW_ERROR:
      return { ...state, showError: true, ...action.payload }
    case ErrorTypes.CLEAR_ERROR:
      return { ...INITIAL_STATE }
    case ErrorTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
