import { BaseServices, HttpMethod } from '@services/base'

export default (data: AverbacaoExercitoRequest, newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_POST_AVERBACAO_EXERCITO_BFF
    : process.env.REACT_APP_POST_AVERBACAO_EXERCITO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = BaseServices.request<AverbacaoExercitoResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface AverbacaoExercitoRequest {
  proposta: string
  cpf: string
  matricula: string
  senhaServidor: string
}

export interface AverbacaoExercitoResponse {
  tentativas?: number
  codRetorno?: string
  sucesso?: boolean
  dsRetCode?: string
}
