import { Reducer } from 'redux'

import { TokenState, TokenTypes } from './types'

const INITIAL_STATE: TokenState = {
  error: undefined,
  loading: false,
  titleError: undefined,
  errorCallback: undefined,
  stepError: undefined,
  tokenValidado: false,
  isValid: true,
}

const reducer: Reducer<TokenState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TokenTypes.TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.mensagem,
        titleError: action.payload.title,
        errorCallback: action.payload.callback,
        stepError: action.payload.step,
        isValid: false,
      }
    case TokenTypes.TOKEN_SUCCESS:
      return {
        ...INITIAL_STATE,
        isValid: true,
        loading: false,
      }
    case TokenTypes.VALIDATE_TOKEN_REQUEST:
      return {
        ...INITIAL_STATE,
        loading: true,
      }
    case TokenTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...INITIAL_STATE,
        tokenValidado: true,
      }
    case TokenTypes.RESET_TOKEN:
      return { ...INITIAL_STATE }

    case TokenTypes.SET_LOADING:
      return { ...state, loading: action.payload }

    default:
      return state
  }
}

export default reducer
