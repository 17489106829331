import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import { getEstados, getCidades, postAutorizacaoISafeSaga } from './sagas'
import { GlobalApiTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(GlobalApiTypes.ESTADOS_REQUEST, getEstados)
  yield* takeLatest(GlobalApiTypes.CIDADES_REQUEST, getCidades)
  yield* takeLatest(GlobalApiTypes.POST_ISAFE_AUTORIZACAO, postAutorizacaoISafeSaga)
}
