import { action, Action } from 'typesafe-actions'

import { Anexo, ContrachequeDocumentosTypes, S3Type } from './types'

export const setInputFiles = (payload: string | number | undefined): Action =>
  action(ContrachequeDocumentosTypes.SET_INPUT_FILES_CONTRACHEQUE, payload)

export const setAnexos = (payload: Anexo): Action =>
  action(ContrachequeDocumentosTypes.SET_ANEXOS_CONTRACHEQUE, payload)

export const sendToS3PaySlip = (payload: S3Type): Action =>
  action(ContrachequeDocumentosTypes.SEND_TO_S3_PAYSLIP_SAGA, payload)

export const deleteAnexo = (payload: number): Action =>
  action(ContrachequeDocumentosTypes.DELETE_ANEXO_CONTRACHEQUE, payload)

export const setIsDone = (payload: boolean): Action =>
  action(ContrachequeDocumentosTypes.SET_IS_DONE_CONTRACHEQUE, payload)

export const setIsLoading = (payload: boolean): Action =>
  action(ContrachequeDocumentosTypes.SET_IS_LOADING_CONTRACHEQUE, payload)

export const setIsError = (payload: boolean): Action =>
  action(ContrachequeDocumentosTypes.SET_IS_ERROR_CONTRACHEQUE, payload)

export const setRollbackRouterError = (payload: string | number | undefined): Action =>
  action(ContrachequeDocumentosTypes.SET_ROLLBACK_ROUTER_ERROR_CONTRACHEQUE, payload)
