import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const Link = styled.a`
  color: ${Colors.ORANGE500};
  background-color: transparent;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`
