import React from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { P, Colors } from '@interco/cp-react-ui-lib'
import { InterUIProgressBar } from '@interco/inter-ui-react-lib'
import { ApplicationState } from '@store/types'

const ProgressBarContainer = styled.div<{
  isApp: boolean
}>`
  ${({ isApp }) =>
    isApp
      ? 'margin-top: 0;'
      : `
    margin-top: 0;
    @media (min-width: 991px){
      margin-top: 0;
    }
  `};
`
interface Props {
  currentStep: number
  totalSteps: number
  visible: boolean
}

const ProgressBarSteps = ({ currentStep, totalSteps, visible }: Props) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const progress = (currentStep / totalSteps) * 100

  return visible ? (
    <ProgressBarContainer isApp={isApp}>
      <InterUIProgressBar progress={`${progress}%`} />
      <P
        variant="inter"
        fontWeight="bold"
        fontSize="12px"
        lineHeight="15px"
        margin="24px 24px 0"
        color={Colors.GRAY400}
      >
        {`Passo ${currentStep}/${totalSteps}`}
      </P>
    </ProgressBarContainer>
  ) : (
    <></>
  )
}

export default ProgressBarSteps
