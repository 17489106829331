enum routes {
  ROOT = '/novo',
  MODO_ENVIO_DOCUMENTOS = '/novo/modo-envio-documentos',
  ANEXO_DOCUMENTO = '/novo/anexo-documento',
  FOTO_DOCUMENTO_INSTRUCOES = '/novo/foto-documento/instrucoes',
  FOTO_DOCUMENTO_FOTO = '/novo/foto-documento/foto',
  BIOMETRIA_INSTRUCOES = '/novo/biometria/instrucoes',
  BIOMETRIA_VIDEO = '/novo/biometria/video',
  RESUMO = '/novo/resumo',
  CONTRATO_SOLICITADO = '/novo/contrato-solicitado',
  LOCAL_NASCIMENTO = '/novo/local-nascimento',
  DADOS_PESSOAIS = '/novo/dados-pessoais',
  DADOS_APP = '/novo/dados-app',
  ENDERECO = '/novo/endereco',
  DADOS_BANCARIOS = '/novo/dados-bancarios',
  MODO_ENVIO_EXTRATO = '/novo/modo-envio-extrato',
  ANEXO_CONTRACHEQUE = '/novo/anexo-contracheque',
  FOTO_EXTRATO = '/novo/foto-extrato',
  NAO_LIBERADO = '/novo/nao-liberado',
  VISUALIZAR_DOCUMENTO = '/novo/visualizar-documento',
  SIAPE_AVERBACAO = '/novo/siape-averbacao',
  EXERCITO_AVERBACAO = '/novo/exercito-averbacao',
  EXERCITO_SENHA_AVERBACAO = '/novo/exercito-senha-averbacao',
  TERMOS_CONTRATACAO = '/novo/termos-contratacao',
  TERMO_VISUALIZACAO = '/novo/termo-visualizacao',
}

export default routes
