import { BaseServices, HttpMethod } from '@services/base'

export interface AssinarDocumentosType {
  documento: string
  origem: string
  fluxo: string
  proposta: string | number | undefined
}

export default async (data: AssinarDocumentosType, newSimulatorApi: boolean) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  const pathname = newSimulatorApi
    ? process.env.REACT_APP_ASSINAR_DOCUMENTO_BFF
    : process.env.REACT_APP_ASSINAR_DOCUMENTO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request<AssinaturaResponse>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface AssinaturaResponse {
  ip: string
  assinatura: string
  dataCriacao: string
}
