/**
 * Actions Types
 */

export enum IndicacaoTypes {
  SET_CODIGO_INDICACAO = '@indicacaoProposta/SET_CODIGO_INDICACAO',
  VALIDA_INDICACAO_REQUEST = '@indicacaoProposta/VALIDA_INDICACAO_REQUEST',
  VALIDA_INDICACAO_ERROR = '@indicacaoProposta/VALIDA_INDICACAO_ERROR',
  VALIDA_INDICACAO_SUCCESS = '@indicacaoProposta/VALIDA_INDICACAO_SUCCESS',
  CRIAR_INDICACAO_PROPOSTA_SAGA = '@indicacaoProposta/CRIAR_INDICACAO_PROPOSTA_SAGA',
  INDICACAO_DONE = '@indicacaoProposta/INDICACAO_DONE',
  SET_CODIGO_APLICADO = '@indicacaoProposta/SET_CODIGO_APLICADO',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface IndicacaoState {
  readonly codigoIndicacao: string
  readonly codigoError: boolean
  readonly codigoLoading: boolean
  readonly codigoDone: boolean
  readonly codigoAplicado: boolean
}

export type IndicacaoPayload = {
  success: () => void
  error: () => void
}

export type CriarIndicacaoPayload = {
  callback: () => void
}
