import { Reducer } from 'redux'

import { CriacaoPropostaState, CriacaoPropostaTypes } from './types'

const INITIAL_STATE: CriacaoPropostaState = {
  nome: '',
  dataCriada: '',
  numeroProposta: undefined,
  loading: false,
  error: false,
  tipoDocumento: undefined,
  idIteracao: '',
  documentoId: '',
}

const reducer: Reducer<CriacaoPropostaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CriacaoPropostaTypes.SET_NOME:
      return { ...state, nome: action.payload }

    case CriacaoPropostaTypes.SET_DATA_CRIADA:
      return { ...state, dataCriada: action.payload }

    case CriacaoPropostaTypes.SET_NUMERO_PROPOSTA:
      return { ...state, numeroProposta: action.payload }

    case CriacaoPropostaTypes.SET_TIPO_DOCUMENTO:
      return { ...state, tipoDocumento: action.payload }

    case CriacaoPropostaTypes.SET_ID_ITERACAO:
      return { ...state, idIteracao: action.payload }

    case CriacaoPropostaTypes.SET_DOCUMENTO_ID:
      return { ...state, documentoId: action.payload }

    case CriacaoPropostaTypes.CRIAR_PROPOSTA:
      return { ...state, loading: true }
    case CriacaoPropostaTypes.CRIAR_PROPOSTA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        numeroProposta: action.payload,
      }
    case CriacaoPropostaTypes.CRIAR_PROPOSTA_ERROR:
      return { ...state, loading: false, error: true }
    case CriacaoPropostaTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
