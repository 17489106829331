import { BaseServices, HttpMethod } from '@services/base'

export default async (data: DocumentoAssinaturaRequest, newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_ATUALIZAR_STATUS_ASSINATURA_BFF
    : process.env.REACT_APP_ATUALIZAR_STATUS_ASSINATURA
  const method = HttpMethod.PUT
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface DocumentoAssinaturaRequest {
  assinaturas: string[]
}
