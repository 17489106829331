import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'

import {
  postFotoBiometriaLiveness,
  setLoadingLiveness,
  setRetryLiveness,
  setUnicoId,
} from '../actions'

interface Props {
  payload: {
    base64: string
    encrypted: string
    callback: () => void
  }
  type: string
}

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}

export default function* postFotoBiometriaLivenessSaga({ payload }: Props) {
  const { cpfUser, nomeUser, telefoneUser, emailUser } = yield* select(
    (s: ApplicationState) => s.user,
  )
  const { nome, email, telefone } = yield* select((s: ApplicationState) => s.captura.documentos)
  const { cpf } = yield* select((s: ApplicationState) => s.simulacao)
  const { numeroProposta } = yield* select((s: ApplicationState) => s.proposta.criacaoProposta)
  try {
    const response = yield* call(services.postLivenessBiometria, {
      document: cpf || cpfUser,
      name: nome || nomeUser,
      email: email || emailUser,
      celular: telefone || telefoneUser,
      proposalCode: numeroProposta ? String(numeroProposta) : '',
      onlySelfie: true,
      photo: payload.base64,
      encryptedPhoto: payload.encrypted,
      isFullCaptureFlow: true,
    })

    yield put(setUnicoId(response.data.unicoId))
    yield put(setLoadingLiveness(false))
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(setLoadingLiveness(false))
    if (
      erroTyped?.status === HttpStatus.PRECONDITION_FAILED ||
      erroTyped?.status === HttpStatus.BAD_REQUEST
    ) {
      yield put(
        showError({
          title:
            erroTyped?.message ||
            erroTyped?.data?.message ||
            erroTyped?.response.data.message ||
            'Ocorreu um erro ao validar a biometria',
          message: 'Por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => setRetryLiveness(true),
          block: true,
          historyBack: true,
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Ocorreu um erro ao validar a biometria',
          message: 'Por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => postFotoBiometriaLiveness(payload),
          block: true,
        }),
      )
    }
  }
}
