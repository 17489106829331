import { EstadosAPI } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_GET_ESTADOS_BFF
    : process.env.REACT_APP_STATES
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<EstadosAPI>({
    pathname,
    method,
    host,
  })
  return response
}
