import { BaseServices, HttpMethod } from '@services/base'

export interface DataAttPropostaType {
  simulacaoId: string
  documentoId: number
}

export default async (data: DataAttPropostaType, newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_PROPOSTA_ATUALIZAR_BFF
    : process.env.REACT_APP_PROPOSTA_ATUALIZAR
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}
