import { Reducer } from 'redux'

import { DocumentosAssinaturaTypes, DocumentosAssinaturaState } from './types'

const INITIAL_STATE: DocumentosAssinaturaState = {
  documentos: [],
  propostaAtualizada: false,
  documentosGerados: false,
  idIteracao: '',
  documentoRevisao: 'CCB',
  checkMessageRevisao: '',
  loading: false,
  tituloDocumentoRevisao: '',
  loadingFinalizarProposta: false,
  erroFinalizarProposta: false,
  assinaturas: [],
  counterDocsParaAssinar: 0,
  loadingEditarLead: false,
}

const addAssinatura = (anexosAtual: string[], anexoNovo: string) => {
  const assinaturas = anexosAtual || []
  assinaturas.push(anexoNovo)
  return assinaturas
}

const reducer: Reducer<DocumentosAssinaturaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DocumentosAssinaturaTypes.SET_DOCUMENTOS:
      return { ...state, documentos: action.payload }
    case DocumentosAssinaturaTypes.SET_PROPOSTA_ATUALIZADA:
      return { ...state, propostaAtualizada: action.payload }
    case DocumentosAssinaturaTypes.SET_DOCUMENTOS_GERADOS:
      return { ...state, documentosGerados: action.payload }
    case DocumentosAssinaturaTypes.SET_ID_ITERACAO:
      return { ...state, idIteracao: action.payload }
    case DocumentosAssinaturaTypes.SET_DOCUMENTO_REVISAO:
      return { ...state, documentoRevisao: action.payload }
    case DocumentosAssinaturaTypes.SET_CHECK_MESSAGE_REVISAO:
      return { ...state, checkMessageRevisao: action.payload }
    case DocumentosAssinaturaTypes.SET_TITLE_DOCUMENTO_REVISAO:
      return { ...state, tituloDocumentoRevisao: action.payload }
    case DocumentosAssinaturaTypes.RESET:
      return { ...INITIAL_STATE }
    case DocumentosAssinaturaTypes.SET_LOADING:
      return { ...state, loading: action.payload }
    case DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA:
      return {
        ...state,
        loadingFinalizarProposta: true,
        erroFinalizarProposta: false,
      }
    case DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA_ERROR:
      return {
        ...state,
        loadingFinalizarProposta: false,
        erroFinalizarProposta: true,
      }
    case DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA_SUCCESS:
      return {
        ...state,
        loadingFinalizarProposta: false,
        erroFinalizarProposta: false,
      }

    case DocumentosAssinaturaTypes.SET_ASSINATURAS:
      return { ...state, assinaturas: addAssinatura(state.assinaturas, action.payload) }
    case DocumentosAssinaturaTypes.RESET_ASSINATURAS:
      return { ...state, assinaturas: [] }
    case DocumentosAssinaturaTypes.SET_COUNTER_DOCUMENTOS_ASSINAR:
      return { ...state, counterDocsParaAssinar: action.payload }
    case DocumentosAssinaturaTypes.SET_LOADING_EDITAR_LEAD:
      return { ...state, loadingEditarLead: action.payload }
    default:
      return state
  }
}

export default reducer
