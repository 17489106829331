import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import { showError } from '@store/ui/error/actions'

import { finalizarProposta, finalizarPropostaErro, finalizarPropostaSuccess } from '../actions'

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}

export default function* postFinalizarPropostaSaga({ payload }: AnyAction) {
  const numeroProposta = yield* select(
    (state: ApplicationState) => state.proposta.criacaoProposta.numeroProposta,
  )
  const { cpf } = yield* select((s: ApplicationState) => s.simulacao)
  const { email, telefone } = yield* select((state: ApplicationState) => state.captura.documentos)
  const { email: emailToken } = yield* select(
    (state: ApplicationState) => state.assinatura.dadosToken,
  )
  const { telefoneCelular } = yield* select(
    (state: ApplicationState) => state.assinatura.resumo.resumo,
  )
  try {
    yield services.postPropostaFinalizar({
      codigoProposta: String(numeroProposta),
      cpf: String(cpf),
      email: email || emailToken,
      telefone: telefone || telefoneCelular,
    })
    yield put(finalizarPropostaSuccess())
    payload.callback()
  } catch (error) {
    yield put(finalizarPropostaErro())
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.NOT_FOUND) {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Ocorreu um erro ao validar o envio da biometria, por favor tente novamente.',
          functionCallback: () => payload.callbackTryAgain(),
          actionTitle: 'Refazer biometria',
          block: true,
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Ocorreu um erro, por favor tente novamente',
          actionTitle: 'Tentar novamente',
          actionCallback: () => finalizarProposta(payload),
          block: true,
        }),
      )
    }
  }
}
