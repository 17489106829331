import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { orgaosRequest } from '@store/captura/documentos/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { orgaosSuccess, orgaosError } from '../actions'

export default function* getOrgaos() {
  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const response = yield* call(services.getOrgaosExpedidores, newSimulatorApi)
    yield* put(orgaosSuccess(response.data?.orgaos))
  } catch (error) {
    yield* put(orgaosError())
    yield* put(
      showError({
        title: 'Erro ao buscar os orgaos',
        message: 'Ocorreu um erro listagem de orgaos.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.C0_001_ORGAOS,
        actionCallback: () => orgaosRequest(),
      }),
    )
  }
}
