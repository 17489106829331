import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import { getResumoDadosSaga } from '@store/assinatura/resumo/sagas'

import sendDocumentosAssinatura from './documentosAssinatura/sagas/sendDocumentosAssinatura'
import { ResumoTypes } from './resumo/types'
import { DocumentosAssinaturaTypes } from './documentosAssinatura/types'
import { editarLeadSaga, finalizarPropostaSaga } from './documentosAssinatura/sagas'
import { AverbacaoExercitoTypes } from './averbacaoExercito/types'
import postValidaSenhaExercitoSaga from './averbacaoExercito/sagas/postValidaSenhaExercito'
import { DadosTokenTypes } from './dadosToken/types'
import { postRealizarAverbacaoSiapeSaga } from './dadosToken/sagas'
import postDocumentoAssincronoSaga from './documentosAssinatura/sagas/postDocumentoAssincronoSaga'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(ResumoTypes.DADOS_RESUMO_REQUEST, getResumoDadosSaga)
  yield* takeLatest(DocumentosAssinaturaTypes.SEND_ASSINATURA, sendDocumentosAssinatura)
  yield* takeLatest(DocumentosAssinaturaTypes.EDITAR_LEAD, editarLeadSaga)
  yield* takeLatest(DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA, finalizarPropostaSaga)
  yield* takeLatest(AverbacaoExercitoTypes.POST_VALIDA_SENHA, postValidaSenhaExercitoSaga)
  yield* takeLatest(
    DadosTokenTypes.REALIZAR_AVERBACAO_SIAPE_REQUEST,
    postRealizarAverbacaoSiapeSaga,
  )
  yield* takeLatest(
    DocumentosAssinaturaTypes.SEND_DOCUMENTO_ASSINCRONO,
    postDocumentoAssincronoSaga,
  )
}
