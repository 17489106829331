import qs from 'qs'

import { BaseServices, HttpMethod } from '@services/base'

export type ValidaIndicacaoRequest = {
  cpfUtilizador: string
  codigo: string
  campanha: string
}

export type ValidaIndicacaoResponse = {
  data: {
    valido: boolean
  }
}

export default (data: ValidaIndicacaoRequest) => {
  const pathname = `${process.env.REACT_APP_VALIDA_CODIGO_INDICACAO}?${qs.stringify(data)}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST

  const response = BaseServices.request<ValidaIndicacaoResponse>({
    pathname,
    method,
    host,
  })
  return response
}
