import { Reducer } from 'redux'

import { NacionalidadeState, NacionalidadeTypes } from './types'

const INITIAL_STATE: NacionalidadeState = {
  paises: [{ value: 0, title: 'Brasil' }],
  idPais: 0,
  titlePais: 'Brasil',

  estadoSelecionado: '',
  cidadeSelecionada: '',
}

const reducer: Reducer<NacionalidadeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NacionalidadeTypes.SET_PAISES:
      return { ...state, paises: action.payload }

    case NacionalidadeTypes.SET_ID_PAIS:
      return { ...state, idPais: action.payload }

    case NacionalidadeTypes.SET_TITLE_PAIS:
      return { ...state, titlePais: action.payload }

    case NacionalidadeTypes.SET_ESTADO_SELECIONADO:
      return { ...state, estadoSelecionado: action.payload }

    case NacionalidadeTypes.SET_CIDADE_SELECIONADA:
      return { ...state, cidadeSelecionada: action.payload }

    case NacionalidadeTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
