import { put, select, call } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import getValidaToken from '@services/getValidaToken'

import { ValidateTokenRequestPayload } from '../types'
import { setError, setLoading, setTokenValido } from '../actions'
import { buildValidateToken } from './models'

export type ErroType = {
  response: {
    status: number
  }
}

export default function* validateTokenSaga(action: AnyAction) {
  const payload = action.payload as ValidateTokenRequestPayload
  const appState = yield* select((state: ApplicationState) => state)

  try {
    yield put(setLoading(true))

    const bodyToken = buildValidateToken(payload.token, appState, 'email')
    const { data } = yield* call(getValidaToken, bodyToken, payload.recaptchaToken)

    if (data && data.tokenValido) {
      yield* put(setTokenValido())

      if (payload?.success) {
        yield payload.success()
      }
    }
    yield put(setLoading(false))
  } catch (error: unknown) {
    yield put(setLoading(false))

    const erroTyped = error as ErroType
    if (erroTyped?.response?.status === HttpStatus.BAD_REQUEST) {
      yield* put(
        setError({
          title: 'Token inválido!',
          mensagem: 'Por favor, confirme o token e tente novamente.',
          step: 'validate-token',
        }),
      )
    } else {
      yield* put(
        setError({
          title: 'Ocorreu um erro!',
          mensagem:
            'Aconteceu um erro interno ao tentar validar seu token! Por favor tente novamente mais tarde.',
          step: 'validate-token',
        }),
      )
    }
  }
}
