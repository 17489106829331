/**
 * Actions Types
 */

export enum DocumentosTypes {
  SET_TIPO_DOCUMENTO = '@captura/documentos/SET_TIPO_DOCUMENTO',
  SET_NUMERO_DOCUMENTO = '@captura/documentos/SET_NUMERO_DOCUMENTO',
  SET_ORGAO_EXPEDIDOR = '@captura/documentos/SET_ORGAO_EXPEDIDOR',
  SET_DATA_EMISSAO = '@captura/documentos/SET_DATA_EMISSAO',
  SET_NOME_MAE = '@captura/documentos/SET_NOME_MAE',
  SET_GENERO = '@captura/documentos/SET_GENERO',
  SET_ESTADO_SELECIONADO = '@captura/documentos/SET_ESTADO_SELECIONADO',

  ORGAO_EXPEDIDOR_SUCCESS = '@captura/documentos/ORGAO_EXPEDIDOR_SUCCESS',
  ORGAO_EXPEDIDOR_ERROR = '@captura/documentos/ORGAO_EXPEDIDOR_ERROR',
  ORGAO_EXPEDIDOR_REQUEST = '@captura/documentos/ORGAO_EXPEDIDOR_REQUEST',

  SHOW_LOADING = '@captura/documentos/SHOW_LOADING',
  HIDE_LOADING = '@captura/documentos/HIDE_LOADING',

  CAPTURA_DOCUMENTOS_REQUEST = '@captura/documentos/CAPTURA_DOCUMENTOS_REQUEST',
  CARREGAR_CAPTURA_REQUEST = '@captura/documentos/CARREGAR_CAPTURA_REQUEST',
  DOCUMENTOS_ASSINATURA_REQUEST = '@captura/documentos/DOCUMENTOS_ASSINATURA_REQUEST',
  BUSCAR_DOCUMENTOS_SAGA = '@captura/documentos/BUSCAR_DOCUMENTOS_SAGA',

  SET_NOME_CLIENTE = '@captura/documentos/SET_NOME_CLIENTE',
  SET_TELEFONE = '@captura/documentos/SET_TELEFONE',
  SET_EMAIL = '@captura/documentos/SET_EMAIL',
  SET_VALOR_LIBERADO = '@captura/documentos/SET_VALOR_LIBERADO',
  SET_VALOR_PARCELA = '@captura/documentos/SET_VALOR_PARCELA',
  SET_QTD_PARCELAS = '@captura/documentos/SET_QTD_PARCELAS',
  SET_TAXA = '@captura/documentos/SET_TAXA',
  SET_CET = '@captura/documentos/SET_CET',
  SET_VALOR_TOTAL = '@captura/documentos/SET_VALOR_TOTAL',
  SET_ID_CLIENT = '@captura/documentos/SET_ID_CLIENT',
  SET_IS_REASSINATURA = '@captura/documentos/SET_IS_REASSINATURA',
  SET_CREDITO_PROTEGIDO = '@captura/documentos/SET_CREDITO_PROTEGIDO',
  RESET = 'RESET',

  SET_ETAPA_BUSCA = '@captura/documentos/SET_ETAPA_BUSCA',
}

export interface OrgaosExpedidorAPI {
  orgaos: string[]
}

/**
 * State Type
 */

export interface DocumentosState {
  readonly tipoDocumento: string
  readonly numeroDocumento: string | number | undefined
  readonly orgaoExpedidor: string | undefined
  readonly dataEmissao: string
  readonly nomeMae?: string | undefined
  readonly genero?: string | undefined
  readonly estadoSelecionado?: string | undefined

  readonly orgaosExpedidores: string[]
  readonly orgaosExpedidoresError: boolean
  readonly orgaosExpedidoresLoading: boolean

  readonly loading: boolean

  readonly nome: string
  readonly telefone: string
  readonly email: string
  readonly valorLiberado: number
  readonly valorParcela: number
  readonly quantidadeParcelas: number
  readonly taxa: number
  readonly valorCET: number
  readonly valorTotal: number
  readonly idClient: string | number
  readonly isReassinatura: boolean
  readonly creditoProtegido: boolean

  readonly etapaBusca: number
}

export interface BuscarPayload {
  callback?: () => void
}
