import { OrgaosExpedidorAPI } from '@store/captura/documentos/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_GET_ORGAO_EXPEDIDOR_BFF
    : process.env.REACT_APP_GET_ORGAO_EXPEDIDOR
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<OrgaosExpedidorAPI>({
    pathname,
    method,
    host,
  })
  return response
}
