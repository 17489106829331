import { Reducer } from 'redux'

import { Anexo, ContrachequeDocumentosState, ContrachequeDocumentosTypes } from './types'

const INITIAL_STATE: ContrachequeDocumentosState = {
  inputFiles: [],
  anexos: [],
  isDone: false,
  isLoading: false,
  isError: false,
  rollbackRouterError: '',
  indice: 0,
}

const deleteAnexo = (anexosAtual: Anexo[], indice: number) => {
  const novaListaAnexos = anexosAtual || []
  novaListaAnexos.splice(indice, 1)
  return novaListaAnexos
}

const addAnexo = (anexosAtual: Anexo[], anexoNovo: Anexo) => {
  const anexos = anexosAtual || []
  anexos.push(anexoNovo)
  return anexos
}

const reducer: Reducer<ContrachequeDocumentosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContrachequeDocumentosTypes.SET_INPUT_FILES_CONTRACHEQUE:
      return { ...state, inputFiles: action.payload }
    case ContrachequeDocumentosTypes.SET_ANEXOS_CONTRACHEQUE:
      return { ...state, anexos: addAnexo(state.anexos, action.payload) }
    case ContrachequeDocumentosTypes.DELETE_ANEXO_CONTRACHEQUE:
      return {
        ...state,
        anexos: deleteAnexo(state.anexos, action.payload),
      }
    case ContrachequeDocumentosTypes.SET_IS_DONE_CONTRACHEQUE:
      return { ...state, isDone: action.payload }
    case ContrachequeDocumentosTypes.SET_IS_LOADING_CONTRACHEQUE:
      return { ...state, isLoading: action.payload }
    case ContrachequeDocumentosTypes.SET_IS_ERROR_CONTRACHEQUE:
      return { ...state, isError: action.payload }
    case ContrachequeDocumentosTypes.SET_ROLLBACK_ROUTER_ERROR_CONTRACHEQUE:
      return { ...state, rollbackRouterError: action.payload }
    case ContrachequeDocumentosTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
