import { action, Action } from 'typesafe-actions'

import { SimulacaoTypes } from './types'

export const setIdSimulacao = (payload: string): Action =>
  action(SimulacaoTypes.SET_ID_SIMULACAO, payload)

export const setIdConvenio = (payload: string): Action =>
  action(SimulacaoTypes.SET_ID_CONVENIO, payload)

export const setClientId = (payload: string): Action =>
  action(SimulacaoTypes.SET_CLIENT_ID, payload)

export const setCpf = (payload: string): Action => action(SimulacaoTypes.SET_CPF, payload)

export const setSimplificadaInss = (payload: boolean): Action =>
  action(SimulacaoTypes.SET_SIMPLIFICADA_INSS, payload)

export const clearStorage = (): Action => action(SimulacaoTypes.RESET)

export const setMatricula = (payload: string | number): Action =>
  action(SimulacaoTypes.SET_MATRICULA, payload)

export const setValorSimulado = (payload: string | number): Action =>
  action(SimulacaoTypes.SET_VALOR_SIMULADO, payload)
export const setName = (payload: string): Action => action(SimulacaoTypes.SET_NAME, payload)
export const setEmail = (payload: string): Action => action(SimulacaoTypes.SET_EMAIL, payload)
export const setDataNascimento = (payload: string): Action =>
  action(SimulacaoTypes.SET_DATA_NASCIMENTO, payload)
export const setTelefone = (payload: string): Action => action(SimulacaoTypes.SET_TELEFONE, payload)
