import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import {
  profissoesError,
  profissoesLoading,
  profissoesRequest,
  profissoesSuccess,
} from '../actions'
import { Profissao, ProfissoesAPI } from '../types'

const profissoesBuild = (data: ProfissoesAPI): Profissao[] =>
  data &&
  data?.profissoes.map((profissao) => ({
    codigo: profissao.codigo,
    nome: profissao.nome,
  }))

export default function* getProfissoes() {
  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    yield put(profissoesLoading())
    const response = yield* call(services.getProfissoes, newSimulatorApi)
    yield put(profissoesSuccess(profissoesBuild(response.data)))
  } catch (error) {
    yield put(profissoesError())
    yield put(
      showError({
        title: 'Erro ao buscar as profissões',
        message: 'Ocorreu um erro na listagem de profissões.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_002_PROFISSOES,
        actionCallback: () => profissoesRequest(),
      }),
    )
  }
}
