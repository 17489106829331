import { AnyAction } from 'redux'

/**
 * Actions Types
 */
export enum TokenTypes {
  TOKEN_ERROR = '@token/TOKEN_ERROR',
  TOKEN_SUCCESS = '@token/TOKEN_SUCCESS',
  SEND_TOKEN_REQUEST = '@token/SEND_TOKEN_REQUEST',
  VALIDATE_TOKEN_REQUEST = '@token/VALIDATE_TOKEN_REQUEST',
  RESET_TOKEN = '@token/RESET_TOKEN',
  VALIDATE_TOKEN_SUCCESS = '@token/VALIDATE_TOKEN_SUCCESS',
  SET_LOADING = '@token/SET_LOADING',
}

/**
 * State Type
 */
export interface TokenState {
  readonly error?: string
  readonly loading: boolean
  readonly titleError?: string
  readonly errorCallback?: () => AnyAction
  readonly stepError?: string
  readonly tokenValidado: boolean
  readonly isValid: boolean
}

/**
 * Action Payload Types
 */
export interface SendTokenPayload {
  type: 'email'
  recaptchaToken: string
}
export interface ValidateTokenRequestPayload {
  token: string
  type: 'email'
  recaptchaToken: string
  success: () => void
}
export interface ErrorPayload {
  title: string
  mensagem: string
  callback?: () => AnyAction
  step: string
}
