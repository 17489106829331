/**
 * Actions Types
 */

export enum EnderecoTypes {
  ENDERECO_REQUEST = '@proposta/endereco/ENDERECO_REQUEST',
  ENDERECO_SUCCESS = '@proposta/endereco/ENDERECO_SUCCESS',
  ENDERECO_LOADING = '@proposta/endereco/ENDERECO_LOADING',
  ENDERECO_ERROR = '@proposta/endereco/ENDERECO_ERROR',

  SET_CEP = '@proposta/endereco/SET_CEP',
  SET_TIPO_LOGRADOURO = '@proposta/endereco/SET_TIPO_LOGRADOURO',
  SET_LOGRADOURO = '@proposta/endereco/SET_LOGRADOURO',
  SET_BAIRRO = '@propsota/endereco/SET_BAIRRO',
  SET_NUMERO = '@proposta/endereco/SET_NUMERO',
  SET_COMPLEMENTO = '@proposta/endereco/SET_COMPLEMENTO',
  SET_ESTADO_SELECIONADO = '@proposta/endereco/SET_ESTADO_SELECIONADO',
  SET_CIDADE_SELECIONADA = '@proposta/endereco/SET_CIDADE_SELECIONADA',
  RISCO_ENDERECO_REQUEST = '@proposta/endereco/RISCO_ENDERECO_REQUEST',
  RESET = 'RESET',
}

/**
 * Data Types
 */

export interface Endereco {
  cep: string
  tipoLogradouro: string
  logradouro: string
  bairro: string
  uf: string
  cidade: string
}

/**
 * State Type
 */

export interface EnderecoState {
  readonly endereco: Endereco[]
  readonly enderecoError: boolean
  readonly enderecoLoading: boolean

  readonly cep: string
  readonly tipoLogradouro: string
  readonly logradouro: string
  readonly bairro: string
  readonly numero: string
  readonly complemento: string
  readonly estadoSelecionado: string
  readonly cidadeSelecionada: string
}

/**
 * APIs Type
 */

export interface EnderecoAPI {
  cep: string
  tipoLogradouro: string
  logradouro: string
  bairro: string
  uf: string
  cidade: string
}

export interface EnderecoPayload {
  cep: string
  callback?: () => void
}

export interface RiscoEnderecoPayload {
  callback: (naoLiberado: boolean) => void
}
