import { BaseServices, HttpMethod } from '@services/base'

export interface IFoto {
  files: {
    type: string
    file: string
  }[]
}

export interface IResponseBiometria {
  unicoId: string
}

export default async (data: IFoto, unicoId: string) => {
  const pathname = process.env.REACT_APP_BIOMETRIA_LIVENESS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers = {
    unicoId,
  }

  const response = await BaseServices.request<IResponseBiometria>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
