import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import {
  estadosRequest,
  estadosSuccess,
  estadosError,
  estadosLoading,
} from '@store/globalApi/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { Estado, EstadosAPI } from '../types'

const estadosBuild = (data: EstadosAPI): Estado[] =>
  data && data?.uf.map((estado) => ({ uf: estado, nome: estado }))

export default function* getEstados() {
  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)

    yield put(estadosLoading())
    const response = yield* call(services.getEstados, newSimulatorApi)
    yield put(estadosSuccess(estadosBuild(response.data)))
  } catch (error) {
    yield put(estadosError())
    yield put(
      showError({
        title: 'Erro ao buscar os estados',
        message: 'Ocorreu um erro na listagem de estados.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.G0_001_ESTADOS,
        actionCallback: () => estadosRequest(),
      }),
    )
  }
}
