/**
 * Actions Types
 */

export enum GlobalApiTypes {
  ESTADOS_REQUEST = '@globalApi/ESTADOS_REQUEST',
  ESTADOS_SUCCESS = '@globalApi/ESTADOS_SUCCESS',
  ESTADOS_LOADING = '@globalApi/ESTADOS_LOADING',
  ESTADOS_ERROR = '@globalApi/ESTADOS_ERROR',

  CIDADES_REQUEST = '@globalApi/CIDADES_REQUEST',
  CIDADES_SUCCESS = '@globalApi/CIDADES_SUCCESS',
  CIDADES_LOADING = '@globalApi/CIDADES_LOADING',
  CIDADES_ERROR = '@globalApi/CIDADES_ERROR',

  POST_ISAFE_AUTORIZACAO = '@globalApi/POST_ISAFE_AUTORIZACAO',
}

/**
 * Data Types
 */

export interface Estado {
  uf: string
  nome: string
}

export interface Cidade {
  codigo: string
  nome: string
}

/**
 * State Type
 */
export interface GlobalApiState {
  readonly estados?: Estado[]
  readonly estadosError?: boolean
  readonly estadosLoading?: boolean

  readonly cidades?: Cidade[]
  readonly cidadesError?: boolean
  readonly cidadesLoading?: boolean
}

/**
 * APIs Type
 */
export interface EstadosAPI {
  uf: string[]
}

export interface CidadesAPI {
  cidades: Cidade[]
}

export interface PayloadAutorizacao {
  callback: () => void
  erro: () => void
  erroAutorizacao: () => void
  token: string
  tipoAutenticacao: string
  valor: string
  conta: string
}
