import React from 'react'

import { Page } from '@interco/cp-react-ui-lib'
import { InnerLoading } from '@molecules'

const FallbackPage = () => (
  <Page id="novo-fallback" isApp hideHeader hasProgressStep={false}>
    <InnerLoading height="150px" size="xl" thickness={2} />
  </Page>
)

export default FallbackPage
