import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import {
  atualizarProposta,
  setPropostaAtualizada,
} from '@store/assinatura/documentosAssinatura/actions'

import { hideLoading, showLoading } from '../actions'

interface Props {
  noRemoveLoader: boolean
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
    block: boolean
  }
  type: string
}

export default function* postAtualizarPropostaSaga({
  noRemoveLoader,
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => atualizarProposta(),
    actionTitle: 'Tentar Novamente',
    block: true,
  },
}: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    yield put(showLoading())
    if (!appState.assinatura.documentosAssinatura.propostaAtualizada) {
      yield services.postAtualizarProposta(
        {
          simulacaoId: String(appState.simulacao.idSimulacao),
          documentoId: Number(appState.proposta.criacaoProposta.documentoId),
        },
        newSimulatorApi,
      )
      yield put(setPropostaAtualizada(true))
    }
    if (!noRemoveLoader) {
      yield put(hideLoading())
    }
    return true
  } catch (error) {
    yield put(showError(actionCallbackError))
    yield put(hideLoading())
    return false
  }
}
