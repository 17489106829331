import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { setLoadingLiveness, setUnicoId } from '@store/captura/videoSelfie/actions'
import { HttpStatus } from '@services/base'
import { generatePDFLiveness } from '@utils/functions'

import { FotoPayload, postFotoDocumentoLiveness, setRetryDocumento } from '../actions'
import { Base64DocType } from '../types'

const transformPayload = (fotosArray: Base64DocType[], pdfFinal: string, tipoDocumento: string) => {
  const regex = /data:.*base64,/
  const files = []
  fotosArray.map((fotoFile) => {
    files.push({
      type: fotoFile.type,
      file: fotoFile.base64.replace(regex, ''),
      encryptedFile: fotoFile.encrypted,
    })
    return ''
  })
  if (tipoDocumento === 'RG') {
    files.push({
      type: 'RG',
      file: pdfFinal.replace(regex, ''),
    })
  }
  return files
}

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}
interface Props {
  payload: FotoPayload
  type: string
}

export default function* postFotoDocumentoLivenessSaga({ payload }: Props) {
  try {
    yield put(setLoadingLiveness(true))
    const { tipoDocumento } = yield* select((s: ApplicationState) => s.captura.documentos)
    const unicoIdAtual = yield* select((s: ApplicationState) => s.captura.videoSelfie.unicoId)

    let pdfFinal = ''
    if (tipoDocumento === 'RG') {
      pdfFinal = yield generatePDFLiveness(payload.base64array)
    } else {
      pdfFinal = payload.base64array.toString()
    }

    const { data } = yield* call(
      services.postDocumentoLiveness,
      {
        files: transformPayload(payload.base64Documentos, pdfFinal, tipoDocumento),
      },
      unicoIdAtual,
    )
    yield put(setUnicoId(data.unicoId))
    yield put(setLoadingLiveness(false))
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(setLoadingLiveness(false))
    if (erroTyped.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Ocorreu um erro ao validar a foto do documento',
          actionCallback: () => setRetryDocumento(true),
          actionTitle: 'Tentar novamente',
          block: true,
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Ocorreu um erro ao enviar a foto do documento',
          actionCallback: () => postFotoDocumentoLiveness(payload),
          actionTitle: 'Tentar novamente',
          block: true,
        }),
      )
    }
  }
}
