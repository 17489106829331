import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postValidaSenhaExercito from '@services/postValidaSenhaExercito'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'

import { postValidaSenha, setAverbacaoValida, setLoading, setTentativas } from '../actions'

export default function* postValidaSenhaExercitoSaga({ payload }: AnyAction) {
  const { senhaAverbacao } = yield* select(
    (state: ApplicationState) => state.assinatura.averbacaoExercito,
  )
  const { matricula, cpf } = yield* select((state: ApplicationState) => state.simulacao)
  const { numeroProposta } = yield* select(
    (state: ApplicationState) => state.proposta.criacaoProposta,
  )
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)

  try {
    yield put(setLoading(true))

    const { data } = yield* call(
      postValidaSenhaExercito,
      {
        proposta: String(numeroProposta),
        cpf,
        matricula: String(matricula),
        senhaServidor: senhaAverbacao,
      },
      newSimulatorApi,
    )

    if (!data.sucesso && data.tentativas) {
      if (data.codRetorno?.toString() === '363') yield put(setAverbacaoValida(false))
      yield put(setTentativas(data.tentativas))
      if (data.dsRetCode && data.codRetorno?.toString() !== '363') {
        yield put(
          showError({
            title: 'Erro ao realizar averbação',
            message: data.dsRetCode,
            actionTitle: 'Tentar Novamente',
            code: ErrorCodes.E0_001_EXERCITO_AVERBACAO,
            actionCallback: () => postValidaSenha(payload),
          }),
        )
      }
      yield payload.callback(false, data.tentativas)
    } else {
      yield put(setAverbacaoValida(true))
      yield payload.callback(true, 0)
    }
    yield put(setLoading(false))
  } catch (error) {
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao realizar averbação',
        message: 'Ocorreu um erro ao realizar averbação.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.E0_001_EXERCITO_AVERBACAO,
        actionCallback: () => postValidaSenha(payload),
      }),
    )
  }
}
