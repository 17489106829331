import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface IResponseBiometriaDados {
  document: string
  name: string
  email: string
  cellPhone: string
  proposalCode: string
}

export default async (biometricToken: string, accessToken?: string) => {
  const pathname = BridgeService.isBrowser()
    ? `portal/bff/v1/${process.env.REACT_APP_BIOMETRICS_DADOS}`
    : process.env.REACT_APP_BIOMETRICS_DADOS
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const headers: Record<string, string> = {
    biometricToken,
  }
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<IResponseBiometriaDados>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
