import { select, call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import postRealizarAverbacaoSiape from '@services/postRealizarAverbacaoSiape'
import { setLoading } from '@store/token/actions'

import { RealizarAverbacaoSiapePayload } from '../types'
import { realizarAverbacaoSiapeRequest, setAverbacaoAutorizada } from '../actions'
import { buildRealizarAverbacaoSiape } from './models'

export default function* postRealizarAverbacaoSiapeSaga(action: AnyAction) {
  const payload = action.payload as RealizarAverbacaoSiapePayload

  try {
    yield put(setLoading(true))
    const { captura, assinatura, proposta, simulacao } = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const body = buildRealizarAverbacaoSiape(captura, assinatura, proposta)
    yield* call(postRealizarAverbacaoSiape, {
      data: body,
      cpf: simulacao?.cpf,
      proposta: String(proposta.criacaoProposta?.numeroProposta),
      newSimulatorApi,
    })

    yield put(setAverbacaoAutorizada(true))
    yield payload.success()

    yield put(setLoading(false))
  } catch (error) {
    yield put(setLoading(false))

    yield put(
      showError({
        title: 'Erro ao realizar averbação',
        message: 'Ocorreu um erro ao realizar averbação.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.S0_001_SIAPE_AVERBACAO,
        actionCallback: () => realizarAverbacaoSiapeRequest(payload),
      }),
    )
  }
}
