/**
 * Actions Types
 */

export enum UserTypes {
  SET_CPF = '@user/SET_CPF',
  SET_TELEFONE = '@user/SET_TELEFONE',
  SET_EMAIL = '@user/SET_EMAIL',
  SET_NOME = '@user/SET_NOME',
  SET_CONTA = '@user/SET_CONTA',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface UserState {
  readonly cpfUser: string
  readonly telefoneUser: string
  readonly emailUser: string
  readonly nomeUser: string
  readonly conta: string
}
