import { Reducer } from 'redux'

import { NavigationState, NavigationTypes } from './types'

const INITIAL_STATE: NavigationState = {
  isApp: true,
  origem: 'app',
  title: 'Proposta',
  hideHeader: false,
  hideFooter: false,
  newSimulatorApi: false,
  familia: 'publico',
  newVersion: false,
  produtoOrigem: '',
  accessToken: undefined,
}

const reducer: Reducer<NavigationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavigationTypes.SET_IS_APP:
      return { ...state, isApp: action.payload }
    case NavigationTypes.SET_ORIGEM:
      return { ...state, origem: action.payload }
    case NavigationTypes.SET_TITLE:
      return { ...state, title: action.payload }
    case NavigationTypes.SET_HIDE_HEADER:
      return { ...state, hideHeader: action.payload }
    case NavigationTypes.SET_HIDE_FOOTER:
      return { ...state, hideFooter: action.payload }
    case NavigationTypes.SET_IS_NEW_SIMULATOR_API:
      return { ...state, newSimulatorApi: action.payload }
    case NavigationTypes.SET_FAMILIA:
      return { ...state, familia: action.payload }
    case NavigationTypes.SET_NEW_VERSION:
      return { ...state, newVersion: action.payload }
    case NavigationTypes.SET_PRODUTO_ORIGEM:
      return { ...state, produtoOrigem: action.payload }
    case NavigationTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload }
    default:
      return state
  }
}

export default reducer
