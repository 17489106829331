import { Reducer } from 'redux'

import { EscolaridadeState, EscolaridadeTypes } from './types'

const INITIAL_STATE: EscolaridadeState = {
  escolaridadeSelecionada: '',

  escolaridades: [],
  escolaridadesError: false,
  escolaridadesLoading: false,

  profissaoSelecionada: '',

  profissoes: [],
  profissoesError: false,
  profissoesLoading: false,

  patrimonios: [],
  keyPatrimonio: 0,
  valuePatrimonio: 0,

  isPep: false,
  pepType: '',

  isUsPerson: false,
  tinNumber: '',
  estadoCivil: '',
}

const reducer: Reducer<EscolaridadeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EscolaridadeTypes.SET_ESCOLARIDADE_SELECIONADA:
      return { ...state, escolaridadeSelecionada: action.payload }

    case EscolaridadeTypes.SET_PROFISSAO_SELECIONADA:
      return { ...state, profissaoSelecionada: action.payload }

    case EscolaridadeTypes.SET_PATRIMONIOS:
      return { ...state, patrimonios: action.payload }

    case EscolaridadeTypes.SET_KEY_PATRIMONIO:
      return { ...state, keyPatrimonio: action.payload }

    case EscolaridadeTypes.SET_VALUE_PATRIMONIO:
      return { ...state, valuePatrimonio: action.payload }

    case EscolaridadeTypes.SET_IS_PEP:
      return { ...state, isPep: action.payload }
    case EscolaridadeTypes.SET_PEP_TYPE:
      return { ...state, pepType: action.payload }

    case EscolaridadeTypes.SET_US_PERSON:
      return { ...state, isUsPerson: action.payload }
    case EscolaridadeTypes.SET_TIN_NUMBER:
      return { ...state, tinNumber: action.payload }

    case EscolaridadeTypes.ESCOLARIDADES_SUCCESS:
      return {
        ...state,
        escolaridadesError: false,
        escolaridadesLoading: false,
        escolaridades: action.payload,
      }
    case EscolaridadeTypes.ESCOLARIDADES_REQUEST:
      return { ...state, escolaridadesError: false, escolaridadesLoading: true }

    case EscolaridadeTypes.ESCOLARIDADES_LOADING:
      return { ...state, escolaridadesError: false, escolaridadesLoading: true }

    case EscolaridadeTypes.ESCOLARIDADES_ERROR:
      return { ...state, escolaridadesLoading: false, escolaridadesError: true }

    case EscolaridadeTypes.PROFISSOES_SUCCESS:
      return {
        ...state,
        profissoesError: false,
        profissoesLoading: false,
        profissoes: action.payload,
      }
    case EscolaridadeTypes.PROFISSOES_REQUEST:
      return { ...state, profissoesError: false, profissoesLoading: true }

    case EscolaridadeTypes.PROFISSOES_LOADING:
      return { ...state, profissoesError: false, profissoesLoading: true }

    case EscolaridadeTypes.PROFISSOES_ERROR:
      return { ...state, profissoesLoading: false, profissoesError: true }
    case EscolaridadeTypes.SET_ESTADO_CIVIL:
      return { ...state, estadoCivil: action.payload }

    case EscolaridadeTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
