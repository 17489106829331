import { Reducer } from 'redux'

import { GlobalApiState, GlobalApiTypes } from './types'

const INITIAL_STATE: GlobalApiState = {
  estados: [],
  estadosError: false,
  estadosLoading: false,

  cidades: [],
  cidadesError: false,
  cidadesLoading: false,
}

const reducer: Reducer<GlobalApiState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GlobalApiTypes.ESTADOS_SUCCESS:
      return {
        ...state,
        estadosError: false,
        estadosLoading: false,
        estados: action.payload,
      }
    case GlobalApiTypes.ESTADOS_REQUEST:
      return { ...state, estadosError: false, estadosLoading: true }

    case GlobalApiTypes.ESTADOS_LOADING:
      return { ...state, estadosError: false, estadosLoading: true }
    case GlobalApiTypes.ESTADOS_ERROR:
      return { ...state, estadosLoading: false, estadosError: true }

    case GlobalApiTypes.CIDADES_SUCCESS:
      return {
        ...state,
        cidadesError: false,
        cidadesLoading: false,
        cidades: action.payload,
      }
    case GlobalApiTypes.CIDADES_REQUEST:
      return { ...state, cidadesError: false, cidadesLoading: true }

    case GlobalApiTypes.CIDADES_LOADING:
      return { ...state, cidadesError: false, cidadesLoading: true }
    case GlobalApiTypes.CIDADES_ERROR:
      return { ...state, cidadesLoading: false, cidadesError: true }

    default:
      return state
  }
}

export default reducer
