import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { setStep } from '@store/ui/steps/actions'
import { PaginaEnum } from '@utils/enums'

interface Props {
  pageAtual: number
  totalSteps?: number
  visible?: boolean
}

const useVerificarStep = ({
  pageAtual,
  totalSteps = PaginaEnum.PAGINA_CINCO,
  visible = true,
}: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setStep({
        currentStep: pageAtual,
        totalSteps,
        visible,
      }),
    )
  }, [dispatch, pageAtual, totalSteps, visible])
}

export default useVerificarStep
