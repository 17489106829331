import { BaseServices, HttpMethod, TResponseType } from './base'

export default async (data: PostAutorizacao, conta: string): Promise<TResponseType<void>> => {
  const headers = {
    'Content-Type': 'application/json',
    'x-inter-conta': conta,
  }

  const pathname = process.env.REACT_APP_POST_AUTORIZACAO_ISAFE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = BaseServices.request<void>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface PostAutorizacao {
  token: string
  tipoAutenticacao: string
  valor: string
}
