import React, { useState, useEffect, useCallback, memo, useRef } from 'react'

import { Paragraph } from '@interco/cp-react-ui-lib'
import { Colors } from '@utils/colors'

import { Container, InputContainer, InputStyled } from './styles'
import { InputTokenProps, ValuesTypes } from './types'

const InputToken = ({
  inputBorderColor = Colors.ORANGE500,
  error = false,
  id,
  size = 6,
  defaultValues = {},
  getToken,
}: InputTokenProps) => {
  const [values, setValues] = useState<ValuesTypes>(defaultValues)
  const [token, setToken] = useState('')
  const [placeholders, setPlaceholders] = useState<ValuesTypes>({})
  const inputRef = useRef<HTMLInputElement>(null)

  const valuesEmpty = useCallback(() => !values || Object.keys(values).length === 0, [values])

  useEffect(() => setToken(values ? Object.values(values).join('') : ''), [values])
  useEffect(
    () => getToken(token, Boolean(token && Object.values(token).length === size)),
    [token, size, getToken],
  )

  useEffect(() => (document.getElementById(`token-${id}-0`) as HTMLInputElement)?.select(), [id])

  // Inicializa Values caso sejam vazios
  useEffect(() => {
    if (valuesEmpty()) {
      const valuesObj: Record<number, number | string> = {}
      Array.from({ length: size }, (v, i) => i).forEach((i) => {
        valuesObj[i] = ''
      })
      setValues(valuesObj)
    }
  }, [values, size, valuesEmpty])

  const handlerValue = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const currentValue = event.target.value
    if (currentValue === '' || Number.isNaN(Number(currentValue))) {
      setValues({ ...values, [index]: '' })
    } else {
      setValues({ ...values, [index]: Number(currentValue) })
      setPlaceholders({ ...placeholders, [index]: currentValue })
      if (index + 1 < size) {
        ;(document.getElementById(`token-${id}-${index + 1}`) as HTMLInputElement)?.select()
      }
    }
  }

  const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement
    if (value !== '' && !Number.isNaN(Number(value))) {
      ;(event.target as HTMLInputElement)?.select()
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // Backspace code
    if (event.keyCode === 8 || event.key === 'Backspace') {
      setValues({ ...values, [index]: '' })
      setPlaceholders({ ...placeholders, [index]: 0 })
      if (index > 0 && (index < size - 1 || values[index] === '')) {
        ;(document.getElementById(`token-${id}-${index - 1}`) as HTMLInputElement).select()
      }
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { value } = document.getElementById(`token-${id}-${index}`) as HTMLInputElement
    if (
      value !== '' &&
      value === event.key &&
      !Number.isNaN(Number(event.key)) &&
      index + 1 < size
    ) {
      if (index + 1 < size)
        (document.getElementById(`token-${id}-${index + 1}`) as HTMLInputElement).select()
      event.preventDefault()
    }
  }

  if (valuesEmpty()) {
    return <></>
  }
  return (
    <>
      <Container>
        {Array.from(Array(size), (v, i) => (
          <InputContainer key={i}>
            <InputStyled
              type="tel"
              maxLength={1}
              minLength={1}
              id={`token-${id}-${i}`}
              placeholder="0"
              tabIndex={size + 1}
              value={values ? values[i] : ''}
              onChange={(event) => handlerValue(event, i)}
              onClick={onClick}
              onKeyDown={(e) => onKeyDown(e, i)}
              onKeyPress={(e) => onKeyPress(e, i)}
              color={inputBorderColor}
              error={error}
              ref={inputRef}
            />
          </InputContainer>
        ))}
      </Container>
      {error && (
        <Paragraph
          variant="inter"
          fontSize="12px"
          lineHeight="15px"
          color={Colors.ERROR500}
          textAlign="center"
          style={{ marginTop: '-15px' }}
        >
          Código de verificação inválido
        </Paragraph>
      )}
    </>
  )
}

InputToken.defaultProps = {
  getToken: undefined,
  size: 6,
  defaultValues: {},
  error: false,
}

export default memo(InputToken)
