/**
 * Actions Types
 */

export enum ResumoTypes {
  DADOS_RESUMO_SUCCESS = '@captura/dados/assinatura/DADOS_RESUMO_SUCCESS',
  DADOS_RESUMO_ERROR = '@captura/dados/assinatura/DADOS_RESUMO_ERROR',
  DADOS_RESUMO_REQUEST = '@captura/dados/assinatura/DADOS_RESUMO_REQUEST',
  RESET = 'RESET',
}

export interface ResumoState {
  readonly resumo: ResumoApi
  readonly resumoError: boolean
}

export interface ResumoApi {
  numeroParcelas: number
  valorParcela: string
  taxa: string
  cet: string
  percentualIOF: string
  valorIOF: string
  valorLiberado: string
  total: string
  primeiroDesconto: string
  ultimoDesconto: string
  taxaNominalContrato: string
  taxaNominalAnual: string
  jurosContratadosPercentual: string
  jurosContratados: string
  valorTotalDestinadoPagamentoSeguro: string
  percentualPropostaSeguroPrestamista: string
  tarifaCadastro: string
  taxaTarifaCadastro: string
  valorSolicitado: string
  valorSolicitadoPercentual: string
  valorOperacao: string
  valorDoMutuo: string
  atributosCetMensal: string
  atributosCetAnual: string
  dataNascimento: string
  nomeMae: string
  valorTotalASerPago: string
  quantidadeDeParcelas: number
  matricula: string
  tipoLiberacao: string
  jurosCapitalizados: boolean
  estipulante: string
  possuiSeguro: boolean
  dataEmissao: string
  enderecoResidencial: string
  cidadeResidencial: string
  estadoResidencial: string
  bairroResidencial: string
  numeroResidencial: string
  nomeCliente: string
  cpf: string
  nomeConjuge: string
  banco: string
  agencia: string
  conta: string
  nomePai: string
  cidadeNaturalidade: string
  estadoNaturalidade: string
  estadoCivil: string
  nacionalidade: string
  documentoIdentificacao: string
  taxaEfetivaMensal: string
  taxaEfetivaAnual: string
  orgao: string
  complementoResidencial: string
  cepResidencial: string
  cepResidencialComMascara: string
  dddCelular: string
  telefoneCelular: string
  codigoContratoOriginalPortabilidade: string
  bancoPortadoDescricao: string
  contratosRefin: ContratosRefinState[]
  saldoDevedorTotalRefin: string
}

export interface ContratosRefinState {
  numeroContratoRefin: string
  valorParcelaContratoRefin: string
  quantidadeParcelaContratoRefin: number
  numeroPropostaRefin: string
  saldoDevedorRefin: string
}
