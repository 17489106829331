import { CidadesAPI } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (estadoUf: string, newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? `${process.env.REACT_APP_GET_CIDADES_BFF}?state=${estadoUf}`
    : `${process.env.REACT_APP_GET_CIDADES}/${estadoUf}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<CidadesAPI>({
    pathname,
    method,
    host,
  })
  return response
}
