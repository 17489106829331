import { Reducer } from 'redux'

import { EnvState, EnvTypes } from './types'

const INITIAL_STATE: EnvState = { activeEnv: 'development' }

const reducer: Reducer<EnvState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EnvTypes.SET_ENV:
      return {
        ...state,
        activeEnv: action.payload,
      }
    default:
      return state
  }
}

export default reducer
