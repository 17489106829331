import { combineReducers } from 'redux'

import error from './error'
import navigation from './navigation'
import steps from './steps'
import { ErrorState } from './error/types'
import { NavigationState } from './navigation/types'
import { StepState } from './steps/types'

export interface UiState {
  error: ErrorState
  navigation: NavigationState
  steps: StepState
}

export default combineReducers({
  error,
  navigation,
  steps,
})
