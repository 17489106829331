import { Reducer } from 'redux'

import { Anexo, InssDocumentosState, InssDocumentosTypes } from './types'

const INITIAL_STATE: InssDocumentosState = {
  inputFilesInss: [],
  anexosInss: [],
  isDoneInss: false,
  isLoadingInss: false,
  isErrorInss: false,
  rollbackRouterErrorInss: '',
  indiceInss: 0,
}

const deleteAnexo = (anexosAtual: Anexo[], indiceInss: number) => {
  const novaListaAnexos = anexosAtual || []
  novaListaAnexos.splice(indiceInss, 1)
  return novaListaAnexos
}

const addAnexo = (anexosAtual: Anexo[], anexoNovo: Anexo) => {
  const anexos = anexosAtual || []
  anexos.push(anexoNovo)
  return anexos
}

const reducer: Reducer<InssDocumentosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InssDocumentosTypes.SET_INPUT_FILES_INSS:
      return { ...state, inputFilesInss: action.payload }
    case InssDocumentosTypes.SET_ANEXOS_INSS:
      return {
        ...state,
        anexosInss: addAnexo(state.anexosInss, action.payload),
      }
    case InssDocumentosTypes.DELETE_ANEXO_INSS:
      return {
        ...state,
        anexosInss: deleteAnexo(state.anexosInss, action.payload),
      }
    case InssDocumentosTypes.SET_IS_DONE_INSS:
      return { ...state, isDoneInss: action.payload }
    case InssDocumentosTypes.SET_IS_LOADING_INSS:
      return { ...state, isLoadingInss: action.payload }
    case InssDocumentosTypes.SET_IS_ERROR_INSS:
      return { ...state, isErrorInss: action.payload }
    case InssDocumentosTypes.SET_ROLLBACK_ROUTER_ERROR_INSS:
      return { ...state, rollbackRouterErrorInss: action.payload }
    case InssDocumentosTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
