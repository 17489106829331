import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import services from '@services'

import { buildToken } from './models'
import { sendToken, setError, setLoading, tokenSuccess } from '../actions'
import { SendTokenPayload } from '../types'

export default function* sendTokenSaga(action: AnyAction) {
  const payload = action.payload as SendTokenPayload

  const appState = yield* select((state: ApplicationState) => state)
  const bodyToken = buildToken(appState, payload.type)

  try {
    yield put(setLoading(true))

    yield services.postEnviaToken(bodyToken, payload.recaptchaToken)
    yield* put(tokenSuccess())
    yield put(setLoading(false))
  } catch (error) {
    yield put(setLoading(false))

    yield* put(
      setError({
        title: 'Ocorreu um erro!',
        mensagem: 'Aconteceu um erro ao enviar token. Tente novamente mais tarde!',
        callback: () => sendToken(payload),
        step: 'create-codigo',
      }),
    )
  }
}
