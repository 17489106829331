/**
 * Actions Types
 */

export enum EscolaridadeTypes {
  SET_ESCOLARIDADE_SELECIONADA = '@proposta/escolaridade/SET_ESCOLARIDADE_SELECIONADA',

  ESCOLARIDADES_REQUEST = '@proposta/escolaridade/ESCOLARIDADES_REQUEST',
  ESCOLARIDADES_SUCCESS = '@proposta/escolaridade/ESCOLARIDADES_SUCCESS',
  ESCOLARIDADES_LOADING = '@proposta/escolaridade/ESCOLARIDADES_LOADING',
  ESCOLARIDADES_ERROR = '@proposta/escolaridade/ESCOLARIDADES_ERROR',

  SET_PROFISSAO_SELECIONADA = '@proposta/escolaridade/SET_PROFISSAO_SELECIONADA',

  PROFISSOES_REQUEST = '@proposta/escolaridade/PROFISSOES_REQUEST',
  PROFISSOES_SUCCESS = '@proposta/escolaridade/PROFISSOES_SUCCESS',
  PROFISSOES_LOADING = '@proposta/escolaridade/PROFISSOES_LOADING',
  PROFISSOES_ERROR = '@proposta/escolaridade/PROFISSOES_ERROR',

  SET_PATRIMONIOS = '@proposta/escolaridade/SET_PATRIMONIOS',
  SET_KEY_PATRIMONIO = '@proposta/escolaridade/SET_KEY_PATRIMONIO',
  SET_VALUE_PATRIMONIO = '@proposta/escolaridade/SET_VALUE_PATRIMONIO',

  SET_IS_PEP = '@proposta/escolaridade/SET_IS_PEP',
  SET_PEP_TYPE = '@proposta/escolaridade/SET_PEP_TYPE',

  SET_US_PERSON = '@proposta/escolaridade/SET_US_PERSON',
  SET_TIN_NUMBER = '@proposta/escolaridade/SET_TIN_NUMBER',
  SET_ESTADO_CIVIL = '@proposta/escolaridade/SET_ESTADO_CIVIL',

  RESET = 'RESET',
}

/**
 * Data Types
 */

export interface Escolaridade {
  codigo: string
  descricao: string
}

export interface Profissao {
  codigo: string
  nome: string
}

export interface Patrimonio {
  key: number
  descricao: string
  value: number
}

/**
 * State Type
 */

export interface EscolaridadeState {
  readonly escolaridadeSelecionada?: string | undefined

  readonly escolaridades?: Escolaridade[]
  readonly escolaridadesError?: boolean
  readonly escolaridadesLoading?: boolean

  readonly profissaoSelecionada?: string | undefined

  readonly profissoes?: Profissao[]
  readonly profissoesError?: boolean
  readonly profissoesLoading?: boolean

  readonly patrimonios?: Patrimonio[]
  readonly keyPatrimonio?: string | number | undefined
  readonly valuePatrimonio?: string | number | undefined

  readonly isPep: boolean
  readonly pepType: string

  readonly isUsPerson: boolean
  readonly tinNumber: string

  readonly estadoCivil: string
}

/**
 * APIs Type
 */

export interface EscolaridadesAPI {
  escolaridades: Escolaridade[]
}

export interface ProfissoesAPI {
  profissoes: Profissao[]
}
