import { addMonths, addDays, differenceInYears, format } from 'date-fns'

const DateFormat = (date: Date, pattern = 'YYYY-MM-DDTHH:mm:ss.SSSZZ') => format(date, pattern)

const CurrentDate = () => DateFormat(new Date())

const describeAge = (date: Date, currentDate = new Date(), hasSumDate = false) => {
  const now = currentDate
  let birthday = addMonths(date, 1)
  birthday = addDays(birthday, 1)
  let years = differenceInYears(now, birthday)

  if (
    date.getMonth() + 1 === currentDate.getMonth() + 1 &&
    date.getDate() > currentDate.getDate() &&
    hasSumDate
  ) {
    years += 1
  }
  return years
}

const ConvertDateUTC = (date: string) => {
  const newDate = new Date(date)
  newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000)
  return newDate
}

export { DateFormat, CurrentDate, describeAge, ConvertDateUTC }
