import { applyMiddleware, combineReducers, createStore, Store, compose } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import captura from './captura'
import assinatura from './assinatura'
import env from './env'
import rootSaga from './rootSaga'
import simulacao from './simulacao'
import ui from './ui'
import globalApi from './globalApi'
import { ApplicationState } from './types'
import proposta from './proposta'
import token from './token'
import indicacaoProposta from './indicacaoProposta'
import user from './user'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))

export const reducers = combineReducers({
  env: persistReducer({ key: 'env', storage }, env),
  captura: persistReducer({ key: 'captura', storage }, captura),
  assinatura: persistReducer({ key: 'assinatura', storage }, assinatura),
  simulacao: persistReducer({ key: 'simulacao', storage }, simulacao),
  ui: persistReducer({ key: 'ui', storage }, ui),
  globalApi: persistReducer({ key: 'globalApi', storage }, globalApi),
  proposta: persistReducer({ key: 'proposta', storage }, proposta),
  token: persistReducer({ key: 'token', storage }, token),
  indicacaoProposta: persistReducer({ key: 'indicacaoProposta', storage }, indicacaoProposta),
  user: persistReducer({ key: 'user', storage }, user),
})

const store: Store<ApplicationState> = createStore(reducers, enhancer)

const persistor = persistStore(store)
const envState = () => store.getState().env
sagaMiddleware.run(rootSaga)

export { store, persistor, envState }
