import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import getResumoDadosSaga from '@store/assinatura/resumo/sagas/getResumoDadosSaga'
import { ApplicationState } from '@store/types'

import postCaptureDocuments from './postCaptureDocuments'
import { buscarDocumentos, hideLoading, setEtapaBusca, showLoading } from '../actions'
import carregarCapturaSaga from './carregarCaptura'
import postDocumentosAssinaturaSaga from './postDocumentosAssinatura'
import postAtualizarPropostaSaga from './atualizarProposta'

interface Props {
  callback?: () => void
  type: string
}

const callbackError = (payload: Props) => ({
  message: 'Aguarde um instante e tente novamente.',
  actionCallback: () => buscarDocumentos(payload),
  actionTitle: 'Tentar Novamente',
  block: true,
})

export default function* buscarDocumentosSaga(action: AnyAction) {
  const payload = action.payload as Props
  const { etapaBusca } = yield* select((state: ApplicationState) => state.captura.documentos)
  try {
    yield put(showLoading())

    let success = true
    if (!etapaBusca || etapaBusca <= 1) {
      yield put(setEtapaBusca(1))
      success = yield* postCaptureDocuments({
        noRemoveLoader: true,
        actionCallbackError: callbackError(payload),
        type: '',
      })
    }
    if (success && etapaBusca <= 2) {
      yield put(setEtapaBusca(2))
      success = yield* carregarCapturaSaga({
        notShowLoader: true,
        actionCallbackError: callbackError(payload),
        type: '',
      })
    }
    if (success && etapaBusca <= 3) {
      yield put(setEtapaBusca(3))
      success = yield* postAtualizarPropostaSaga({
        noRemoveLoader: true,
        actionCallbackError: callbackError(payload),
        type: '',
      })
    }
    if (success && etapaBusca <= 4) {
      yield put(setEtapaBusca(4))
      success = yield* postDocumentosAssinaturaSaga({
        noRemoveLoader: true,
        actionCallbackError: callbackError(payload),
        type: '',
      })
    }
    if (success && etapaBusca <= 5) {
      yield put(setEtapaBusca(5))
      success = yield* getResumoDadosSaga({
        actionCallbackError: callbackError(payload),
        type: '',
      })
    }

    if (success) {
      yield put(hideLoading())
      if (payload.callback) {
        yield payload.callback()
        return true
      }
      return true
    }
    yield put(hideLoading())
    return false
  } catch (error) {
    yield put(hideLoading())
    return false
  }
}
