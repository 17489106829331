import { ValidaCodigoApiRequest } from '@services/getValidaToken'
import { EnviaCodigoApiRequest } from '@services/postEnviaToken'
import { ApplicationState } from '@store/types'

export const buildToken = ({ simulacao, captura }: ApplicationState, envio: 'email') => {
  const objetoEnvio: EnviaCodigoApiRequest = {
    nome: captura.documentos.nome,
    cpf: simulacao.cpf,
    email: captura.documentos.email,
    celular: captura.documentos.telefone,
    envio: [envio === 'email' ? 'EMAIL' : 'SMS'],
  }

  return objetoEnvio
}

export const buildValidateToken = (
  codigo: string,
  { simulacao }: ApplicationState,
  origem: 'email',
) => {
  const objetoValidacao: ValidaCodigoApiRequest = {
    codigo,
    id: simulacao.cpf,
    origem: origem === 'email' ? 'EMAIL' : 'SMS',
  }

  return objetoValidacao
}
