import { combineReducers } from 'redux'

import contrachequeDocumentos from './contrachequeDocumentos'
import { ContrachequeDocumentosState } from './contrachequeDocumentos/types'
import documentos from './documentos'
import { DocumentosState } from './documentos/types'
import fotoDocumentos from './fotoDocumentos'
import { FotoDocumentosState } from './fotoDocumentos/types'
import inssDocumentos from './inssDocumentos'
import { InssDocumentosState } from './inssDocumentos/types'
import videoSelfie from './videoSelfie'
import { VideoSelfieState } from './videoSelfie/types'

export interface CapturaState {
  documentos: DocumentosState
  fotoDocumentos: FotoDocumentosState
  videoSelfie: VideoSelfieState
  inssDocumentos: InssDocumentosState
  contrachequeDocumentos: ContrachequeDocumentosState
}

export default combineReducers({
  documentos,
  fotoDocumentos,
  videoSelfie,
  inssDocumentos,
  contrachequeDocumentos,
})
