/**
 * Actions Types
 */

export enum AverbacaoExercitoTypes {
  SET_SENHA_AVERBACAO = '@assinatura/averbacaoExercito/SET_SENHA_AVERBACAO',
  SET_AVERBACAO_VALIDA = '@assinatura/averbacaoExercito/SET_AVERBACAO_VALIDA',
  SET_TENTATIVAS = '@assinatura/averbacaoExercito/SET_TENTATIVAS',
  POST_VALIDA_SENHA = '@assinatura/averbacaoExercito/POST_VALIDA_SENHA',
  SET_LOADING = '@assinatura/averbacaoExercito/SET_LOADING',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface AverbacaoExercitoState {
  readonly senhaAverbacao: string
  readonly averbacaoValida: boolean
  readonly tentativas: number
  readonly loading: boolean
}

export type ValidaPayload = {
  callback: (isValido: boolean | undefined, tentativas: number) => void
}
