import { ProfissoesAPI } from '@store/proposta/escolaridade/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_PROFESSIONS_BFF
    : process.env.REACT_APP_PROFESSIONS
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<ProfissoesAPI>({
    pathname,
    method,
    host,
  })
  return response
}
