import { BaseServices, HttpMethod } from '@services/base'

export interface FinalizarPropostaType {
  codigoProposta: string
  cpf: string
  email: string
  telefone: string
}

export default async (data: FinalizarPropostaType) => {
  const pathname = process.env.REACT_APP_PROPOSTA_FINALIZAR_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}
