/**
 * Actions Types
 */

export enum NacionalidadeTypes {
  SET_PAISES = '@proposta/nacionalidade/SET_PAISES',
  SET_ID_PAIS = '@proposta/nacionalidade/SET_ID_PAIS',
  SET_TITLE_PAIS = '@proposta/nacionalidade/SET_TITLE_PAIS',

  SET_ESTADO_SELECIONADO = '@proposta/nacionalidade/SET_ESTADO_SELECIONADO',
  SET_CIDADE_SELECIONADA = '@proposta/nacionalidade/SET_CIDADE_SELECIONADA',

  RESET = 'RESET',
}

/**
 * Data Types
 */
export interface Pais {
  value: number
  title: string
}

/**
 * State Type
 */

export interface NacionalidadeState {
  readonly paises?: Pais[]
  readonly idPais?: number | undefined
  readonly titlePais?: string | undefined

  readonly estadoSelecionado?: string | undefined
  readonly cidadeSelecionada?: string | undefined
}
