import { action, Action } from 'typesafe-actions'

import { AverbacaoExercitoTypes, ValidaPayload } from './types'

export const setTentativas = (payload: number): Action =>
  action(AverbacaoExercitoTypes.SET_TENTATIVAS, payload)
export const setAverbacaoValida = (payload: boolean): Action =>
  action(AverbacaoExercitoTypes.SET_AVERBACAO_VALIDA, payload)
export const setSenhaAverbacao = (payload: string): Action =>
  action(AverbacaoExercitoTypes.SET_SENHA_AVERBACAO, payload)

export const postValidaSenha = (payload: ValidaPayload): Action =>
  action(AverbacaoExercitoTypes.POST_VALIDA_SENHA, payload)

export const setLoading = (payload: boolean): Action =>
  action(AverbacaoExercitoTypes.SET_LOADING, payload)
