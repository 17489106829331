import React, { useCallback, useEffect, useState } from 'react'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { ErrorMessage } from '@components/organisms'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { enableDarkMode, initTheme, Theme } from '@interco/inter-ui'
import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib'

import { store, persistor } from '../store'
import Routes from '../routes'

import './App.scss'

const App = () => {
  const [theme, setTheme] = useState('LIGHT')
  InterUIFonts()

  const getAppInfo = useCallback(async () => {
    const response = await interWbSession.getAppInfo()
    if (JSON.parse(JSON.stringify(response)).isDarkMode === true) {
      enableDarkMode()
      setTheme('DARK')
    }
  }, [])
  useEffect(() => {
    if (!BridgeService.isBrowser()) getAppInfo()
    initTheme(Theme.PF)
  }, [getAppInfo])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledThemeProvider theme={InterTheme(theme)}>
          <GlobalUIInterStyle />
          <ErrorMessage />
          <Routes />
        </StyledThemeProvider>
      </PersistGate>
    </Provider>
  )
}
export default App
