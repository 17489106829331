import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import sendTokenSaga from './sagas/sendToken'
import validateTokenSaga from './sagas/validateToken'
import { TokenTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(TokenTypes.SEND_TOKEN_REQUEST, sendTokenSaga)
  yield* takeLatest(TokenTypes.VALIDATE_TOKEN_REQUEST, validateTokenSaga)
}
