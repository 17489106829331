import { BaseServices, HttpMethod } from '@services/base'

export interface DataType {
  cpf: string
  proposta: string
  clientId: string
  newSimulatorApi: boolean
}

export default ({ cpf, proposta, clientId, newSimulatorApi }: DataType) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_GET_CAPTURA_BFF
    : `${process.env.REACT_APP_GET_CAPTURA}?cpf=${cpf}&proposta=${proposta}&clientid=${clientId}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST
  let headers = {}
  if (newSimulatorApi) {
    headers = {
      cpf,
      proposta,
      clientId,
    }
  }
  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
