import React, { useEffect, useState, memo, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { P } from '@interco/cp-react-ui-lib'
import { InterUIButton } from '@interco/inter-ui-react-lib'
import { resetToken } from '@store/token/actions'
import { InnerLoading, InputToken } from '@molecules'
import { ocultarEmail } from '@utils/masks'
import { StepsEnum } from '@utils/enums'
import { trackingNewRelic } from '@store/proposta/criacaoProposta/actions'
import { ApplicationState } from '@store/types'
import { useGoogleRecaptchaToken } from '@hooks'
import { sendToken } from '@store/token/actions'
import { Colors } from '@utils/colors'

interface Props {
  error?: boolean
  loading: boolean
  email: string
  onSuccess: (token: string) => void
}

const RESEND_TIMER = 60

const PageToken = ({ error, loading, email, onSuccess }: Props) => {
  const dispatch = useDispatch()
  const [token, setToken] = useState('')
  const [filledToken, setFilledToken] = useState(false)
  const [timer, setTimer] = useState(RESEND_TIMER)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { getToken } = useGoogleRecaptchaToken()

  useEffect(() => {
    dispatch(resetToken())
  }, [dispatch])

  useEffect(() => {
    if (filledToken) {
      onSuccess(token)
    }
  }, [filledToken, onSuccess, token])

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer) {
        setTimer(timer - 1)
      }
    }, 1000)
    return () => clearTimeout(time)
  })

  const reenvioToken = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getToken()
      if (recaptchaToken) {
        dispatch(sendToken({ type: 'email', recaptchaToken }))
      }
    } else {
      dispatch(sendToken({ type: 'email', recaptchaToken: 'NULLCAPTCHA' }))
    }
    setTimer(RESEND_TIMER)
  }, [dispatch, getToken, isApp])

  return (
    <>
      {loading ? (
        <InnerLoading height="150px" size="ld" thickness={2} />
      ) : (
        <>
          <P variant="inter" fontSize="14px" lineHeight="17px" color={Colors.GRAY400}>
            Pra garantir sua segurança, enviamos um e-mail com o código de verificação para o e-mail{' '}
            {ocultarEmail(email || '')}. Digite o código aqui :
          </P>
          <InputToken
            error={error}
            inputBorderColor={Colors.GRAY200}
            getToken={(t, filled) => {
              setToken(t)
              setFilledToken(filled)
            }}
            id="pageToken"
          />
          <InterUIButton
            disabled={timer > 0}
            variant="secondary"
            onClick={() => {
              dispatch(resetToken())
              dispatch(trackingNewRelic(StepsEnum.REENVIAR_TOKEN_EMAIL))
              reenvioToken()
            }}
            style={{
              margin: '10px auto 0',
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          >
            {`Reenviar ${timer > 0 ? `(${timer}s)` : ''}`}
          </InterUIButton>
        </>
      )}
    </>
  )
}
PageToken.defaultProps = {
  error: false,
}
export default memo(PageToken)
