import React, { Suspense, lazy } from 'react'

import { Route, Switch } from 'react-router-dom'

import { FallbackPage } from '@components/organisms'

import routes from './routes'

const BiometriaInstrucoes = lazy(() => import('@components/pages/ModuloBiometria/Intrucoes'))
const Biometria = lazy(() => import('@components/pages/ModuloBiometria/Video'))

const Routes = () => (
  <Switch>
    <Suspense fallback={<FallbackPage />}>
      <Route exact path={routes.BIOMETRIA_INSTRUCOES} component={BiometriaInstrucoes} />
      <Route exact path={routes.BIOMETRIA_VIDEO} component={Biometria} />
    </Suspense>
  </Switch>
)

export default Routes
