import React, { Suspense, lazy } from 'react'

import { Route, Switch } from 'react-router-dom'

import { FallbackPage } from '@components/organisms'

import routes from './routes'

const DadosApp = lazy(() => import('@components/pages/Novo/Cliente/DadosApp'))
const LoadingPage = lazy(() => import('@pages/Novo/LoadingPage'))
const ModoEnvioDocumentos = lazy(
  () => import('@components/pages/Novo/Documentos/ModoEnvio/ModoEnvioDocumentos'),
)
const AnexoDocumento = lazy(() => import('@components/pages/Novo/Documentos/Anexos/AnexoDocumento'))
const FotoDocumentoInstrucoes = lazy(
  () => import('@components/pages/Novo/Documentos/Fotos/FotoDocumento/Intrucoes'),
)
const FotoDocumentoFoto = lazy(
  () => import('@components/pages/Novo/Documentos/Fotos/FotoDocumento/Foto'),
)
const BiometriaInstrucoes = lazy(
  () => import('@components/pages/Novo/Documentos/Biometria/Intrucoes'),
)
const BiometriaVideo = lazy(() => import('@components/pages/Novo/Documentos/Biometria/Video'))
const Resumo = lazy(() => import('@components/pages/Novo/Resumo'))
const ContratoSolicitado = lazy(() => import('@components/pages/Novo/ContratoSolicitado'))
const LocalNascimento = lazy(() => import('@components/pages/Novo/Cliente/LocalNascimento'))
const DadosPessoais = lazy(() => import('@components/pages/Novo/Cliente/DadosPessoais'))
const Endereco = lazy(() => import('@components/pages/Novo/Cliente/Endereco'))
const DadosBancarios = lazy(() => import('@components/pages/Novo/Cliente/DadosBancarios'))
const AnexoContracheque = lazy(
  () => import('@components/pages/Novo/Documentos/Anexos/AnexoContracheque'),
)
const ModoEnvioExtrato = lazy(
  () => import('@components/pages/Novo/Documentos/ModoEnvio/ModoEnvioExtrato'),
)
const FotoExtrato = lazy(() => import('@components/pages/Novo/Documentos/Fotos/FotoExtrato'))
const NaoLiberado = lazy(() => import('@components/pages/Novo/NaoLiberado'))
const VisualizarDocumento = lazy(
  () => import('@components/pages/Novo/Documentos/VisualizarDocumento'),
)
const SiapeAverbacao = lazy(() => import('@components/pages/Novo/Averbacao/Siape'))
const ExercitoAverbacao = lazy(
  () => import('@components/pages/Novo/Averbacao/Exercito/ExercitoAverbacao'),
)
const ExercitoSenhaAverbacao = lazy(
  () => import('@components/pages/Novo/Averbacao/Exercito/SenhaAverbacao'),
)

const TermosContratacao = lazy(() => import('@components/pages/Novo/Termos'))
const TermoVisualizacao = lazy(() => import('@components/pages/Novo/Termos/VisualizadorTermo'))

const Routes = () => (
  <Switch>
    <Suspense fallback={<FallbackPage />}>
      <Route exact path={routes.ROOT} component={LoadingPage} />
      <Route exact path={routes.MODO_ENVIO_DOCUMENTOS} component={ModoEnvioDocumentos} />
      <Route exact path={routes.ANEXO_DOCUMENTO} component={AnexoDocumento} />
      <Route exact path={routes.FOTO_DOCUMENTO_INSTRUCOES} component={FotoDocumentoInstrucoes} />
      <Route exact path={routes.FOTO_DOCUMENTO_FOTO} component={FotoDocumentoFoto} />
      <Route exact path={routes.BIOMETRIA_INSTRUCOES} component={BiometriaInstrucoes} />
      <Route exact path={routes.BIOMETRIA_VIDEO} component={BiometriaVideo} />
      <Route exact path={routes.RESUMO} component={Resumo} />
      <Route exact path={routes.CONTRATO_SOLICITADO} component={ContratoSolicitado} />
      <Route exact path={routes.LOCAL_NASCIMENTO} component={LocalNascimento} />
      <Route exact path={routes.DADOS_PESSOAIS} component={DadosPessoais} />
      <Route exact path={routes.DADOS_APP} component={DadosApp} />
      <Route exact path={routes.ENDERECO} component={Endereco} />
      <Route exact path={routes.DADOS_BANCARIOS} component={DadosBancarios} />
      <Route exact path={routes.ANEXO_CONTRACHEQUE} component={AnexoContracheque} />
      <Route exact path={routes.MODO_ENVIO_EXTRATO} component={ModoEnvioExtrato} />
      <Route exact path={routes.FOTO_EXTRATO} component={FotoExtrato} />
      <Route exact path={routes.NAO_LIBERADO} component={NaoLiberado} />
      <Route exact path={routes.VISUALIZAR_DOCUMENTO} component={VisualizarDocumento} />
      <Route exact path={routes.SIAPE_AVERBACAO} component={SiapeAverbacao} />
      <Route exact path={routes.EXERCITO_AVERBACAO} component={ExercitoAverbacao} />
      <Route exact path={routes.EXERCITO_SENHA_AVERBACAO} component={ExercitoSenhaAverbacao} />
      <Route exact path={routes.TERMOS_CONTRATACAO} component={TermosContratacao} />
      <Route exact path={routes.TERMO_VISUALIZACAO} component={TermoVisualizacao} />
    </Suspense>
  </Switch>
)

export default Routes
