import { Reducer } from 'redux'

import { SimulacaoState, SimulacaoTypes } from './types'

const INITIAL_STATE: SimulacaoState = {
  idSimulacao: '',
  idConvenio: '',
  clientId: '',
  cpf: '',
  simplificadaInss: false,
  matricula: '',
  valorSimulado: '',
  email: '',
  telefone: '',
  nome: '',
  dataNascimento: '',
}

const reducer: Reducer<SimulacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulacaoTypes.SET_ID_SIMULACAO:
      return { ...state, idSimulacao: action.payload }
    case SimulacaoTypes.SET_ID_CONVENIO:
      return { ...state, idConvenio: action.payload }
    case SimulacaoTypes.SET_CLIENT_ID:
      return { ...state, clientId: action.payload }
    case SimulacaoTypes.SET_CPF:
      return { ...state, cpf: action.payload }
    case SimulacaoTypes.SET_SIMPLIFICADA_INSS:
      return { ...state, simplificadaInss: action.payload }
    case SimulacaoTypes.SET_MATRICULA:
      return { ...state, matricula: action.payload }
    case SimulacaoTypes.SET_VALOR_SIMULADO:
      return { ...state, valorSimulado: action.payload }
    case SimulacaoTypes.SET_NAME:
      return { ...state, nome: action.payload }
    case SimulacaoTypes.SET_EMAIL:
      return { ...state, email: action.payload }
    case SimulacaoTypes.SET_DATA_NASCIMENTO:
      return { ...state, dataNascimento: action.payload }
    case SimulacaoTypes.SET_TELEFONE:
      return { ...state, telefone: action.payload }

    case SimulacaoTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
