import { useCallback, useEffect, useState } from 'react'

import MobileBridge from '@interco/inter-mobile-bridge'
import { BridgeService } from '@services/bridge'
import { isOldBridge } from '@services/base/oldBridge'

export interface UserInfoType {
  name?: string
  cpf?: string
  birthDate?: number
  cellPhoneNumber?: string
  phoneNumber?: string
  email?: string
  account?: string
}

const useMobileUserInfo = () => {
  const [userInfo, setUserInfo] = useState<UserInfoType | undefined>(undefined)

  const getUserInfo = useCallback(async () => {
    const data = await BridgeService.getUserInfo()
    setUserInfo(data)
  }, [])

  const getUserInfoOldBridge = useCallback(async () => {
    const data = await MobileBridge.getUserInfo()
    setUserInfo(data)
  }, [])

  useEffect(() => {
    if (isOldBridge() && !BridgeService.isBrowser()) {
      getUserInfoOldBridge()
    } else if (!BridgeService.isBrowser() && !isOldBridge()) {
      getUserInfo()
    }
  }, [getUserInfo, getUserInfoOldBridge])

  return userInfo
}

export default useMobileUserInfo
