import { BaseServices, HttpMethod } from '@services/base'

export interface DataPropostaGerarType {
  cpf: string
  clientId: string | number
  nomeDocumento: string
  numeroProposta: string
  appendPDF64: string
}
export default (data: DataPropostaGerarType) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  const pathname = process.env.REACT_APP_PROPOSTA_GERAR
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = BaseServices.request({ headers, pathname, method, host, data })
  return response
}
