import { BaseServices, HttpMethod } from '@services/base'

export interface PostFotoLivenessType {
  document: string
  name: string
  email: string
  celular: string
  proposalCode: string
  onlySelfie: boolean
  photo: string
  encryptedPhoto: string
}

export default async (data: PostFotoLivenessType) => {
  const pathname = `${process.env.REACT_APP_BIOMETRIA_LIVENESS}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request<{ unicoId: string }>({
    pathname,
    method,
    host,
    data,
  })
  return response
}
