import { combineReducers } from 'redux'

import dadosToken from './dadosToken'
import resumo from './resumo'
import listaDocumentos from './listaDocumentos'
import { DadosTokenState } from './dadosToken/types'
import { ResumoState } from './resumo/types'
import { ListaDocumentosState } from './listaDocumentos/types'
import { DocumentosAssinaturaState } from './documentosAssinatura/types'
import documentosAssinatura from './documentosAssinatura'
import { AverbacaoExercitoState } from './averbacaoExercito/types'
import averbacaoExercito from './averbacaoExercito'

export interface AssinaturaState {
  dadosToken: DadosTokenState
  resumo: ResumoState
  listaDocumentos: ListaDocumentosState
  documentosAssinatura: DocumentosAssinaturaState
  averbacaoExercito: AverbacaoExercitoState
}

export default combineReducers({
  dadosToken,
  resumo,
  listaDocumentos,
  documentosAssinatura,
  averbacaoExercito,
})
