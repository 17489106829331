import { put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { findTipoConta, findTipoCreditoApiInss } from '@utils/functions'

import {
  dadosBancariosInssError,
  dadosBancariosInssRequest,
  dadosBancariosInssSuccess,
  setAgencia,
  setBancoSelecionado,
  setConta,
  setDigito,
  setTipoConta,
  setTipoCreditoInss,
} from '../actions'

export default function* getDadosBancariosInssSaga() {
  const matricula = yield* select((state: ApplicationState) => state.simulacao.matricula)

  try {
    const { data } = yield services.getDadosBancariosInss(matricula?.toString() || '')

    yield put(setBancoSelecionado(data?.banco || ''))
    yield put(setTipoConta(findTipoConta(data?.tipoCredito)?.value))
    yield put(setTipoCreditoInss(findTipoCreditoApiInss(data?.tipoCredito)?.value))
    yield put(setAgencia(data?.agencia || ''))
    yield put(setConta(data?.conta || ''))
    yield put(setDigito(data?.digitoConta || ''))
    yield put(dadosBancariosInssSuccess())
  } catch (error) {
    yield put(dadosBancariosInssError())
    yield put(
      showError({
        title: 'Erro ao buscar dados bancários',
        message: 'Ocorreu um erro busca de dados bancários.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_007_DADOS_BANCARIOS_INSS,
        actionCallback: () => dadosBancariosInssRequest(),
      }),
    )
  }
}
