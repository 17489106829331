import { ResumoApi } from '@store/assinatura/resumo/types'
import { BaseServices, HttpMethod } from '@services/base'

export interface RequestDadosResumo {
  numeroProposta: string | number
  cpf: string
  email: string
  telefone: string
}

export default (data: RequestDadosResumo, isApp: boolean) => {
  const pathname = isApp
    ? process.env.REACT_APP_DADOS_COMPLETOS_CCB
    : process.env.REACT_APP_DADOS_COMPLETOS_CCB_PUBLIC
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<ResumoApi>({
    pathname,
    method,
    host,
    data,
  })
  return response
}
