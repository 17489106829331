import { put, select } from 'typed-redux-saga'
import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import services from '@services'

import { infoValida, setContato, setValidaLoading } from '../actions'
import { ValidaPropostaPayload } from '../types'

export default function* postInfoValidaSaga(action: AnyAction) {
  const payload = action.payload as ValidaPropostaPayload
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
  try {
    yield put(setValidaLoading(true))
    const { documentoId } = yield* select((s: ApplicationState) => s.proposta.criacaoProposta)
    const { data }: AxiosResponse = yield services.postInfoValida(
      {
        documentoId,
      },
      newSimulatorApi,
    )
    yield put(setContato(data?.contato))
    yield put(setValidaLoading(false))
    if (payload.callback) {
      yield payload.callback(data?.contato)
    }
  } catch (error) {
    yield put(setValidaLoading(false))
    yield put(
      showError({
        title: 'Erro ao validar',
        message: 'Ocorreu um erro ao validar suas informações',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.P0_008_VALIDA,
        actionCallback: () => infoValida(payload),
      }),
    )
  }
}
