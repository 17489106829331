import { Reducer } from 'redux'

import { IndicacaoState, IndicacaoTypes } from './types'

const INITIAL_STATE: IndicacaoState = {
  codigoIndicacao: '',
  codigoError: false,
  codigoLoading: false,
  codigoDone: false,
  codigoAplicado: false,
}

const reducer: Reducer<IndicacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IndicacaoTypes.SET_CODIGO_INDICACAO:
      return { ...state, codigoIndicacao: action.payload }
    case IndicacaoTypes.VALIDA_INDICACAO_REQUEST:
      return { ...state, codigoError: false, codigoLoading: true }
    case IndicacaoTypes.VALIDA_INDICACAO_ERROR:
      return { ...state, codigoError: true, codigoLoading: false }
    case IndicacaoTypes.VALIDA_INDICACAO_SUCCESS:
      return { ...state, codigoError: false, codigoLoading: false }
    case IndicacaoTypes.INDICACAO_DONE:
      return {
        ...state,
        codigoError: false,
        codigoLoading: false,
        codigoDone: true,
      }
    case IndicacaoTypes.RESET:
      return { ...INITIAL_STATE }
    case IndicacaoTypes.SET_CODIGO_APLICADO:
      return { ...state, codigoAplicado: action.payload }

    default:
      return state
  }
}

export default reducer
