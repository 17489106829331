import React, { useCallback, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import InterWebviewBridge from '@interco/inter-webview-bridge'
import MobileBridge from '@interco/inter-mobile-bridge'
import { BridgeService } from '@services/bridge'
import routes from '@routes/routes'
import { isOldBridge } from '@services/base/oldBridge'

interface AppGoBackType {
  App: {
    goBack: () => void
  }
}
export type WindowMobileType = AppGoBackType & typeof window

export const GoBackConfig = () => {
  const history = useHistory()
  const location = useLocation()
  const iWb = InterWebviewBridge.getInstance()
  const Window = window as WindowMobileType

  const listenerMobileBack = useCallback(() => {
    if (location?.pathname === routes.ROOT) {
      BridgeService.backToNative()
    } else {
      history.goBack()
    }
  }, [history, location])

  useEffect(() => {
    if (isOldBridge()) {
      Window.App = {
        goBack: () => {
          try {
            if (location?.pathname === routes.ROOT) {
              MobileBridge.goBack()
            } else {
              history.goBack()
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
          }
        },
      }
    } else {
      iWb.addWebviewEventListener('GO_BACK', listenerMobileBack)
      return () => {
        iWb.removeWebviewEventListener('GO_BACK', listenerMobileBack)
      }
    }
    return () => null
  }, [Window, history, iWb, listenerMobileBack, location?.pathname])

  return <></>
}
