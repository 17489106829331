import { BaseServices, HttpMethod } from './base'

export default ({ data, cpf, proposta, newSimulatorApi }: RealizarAverbacaoSiapeService) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_AVERBACAO_SIAPE_BFF
    : process.env.REACT_APP_AVERBACAO_SIAPE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers = { cpf, proposta }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface RealizarAverbacaoSiapeService {
  data: RealizarAverbacaoSiapeRequest
  cpf: string
  proposta: string
  newSimulatorApi: boolean
}

export interface RealizarAverbacaoSiapeRequest {
  cet: string
  dtValidadeAnuencia: string
  iof: string
  nrContrato: string
  pzDesconto: string
  txJurosMensal: string
  vlBruto: string
  vlDesconto: string
  vlLiquido: string
}
