import { BaseServices, HttpMethod } from '@services/base'

export interface DataType {
  tamanho: number
  cpf: string
  nome: string
  tipoDocumentoId: string
}

export default async (data: DataType, newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_CAPTURE_UPLOAD_LINK_BFF
    : process.env.REACT_APP_CAPTURE_UPLOAD_LINK
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request<UploadLinkRequest>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface UploadLinkRequest {
  url: string
}
