import React from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import HeaderAndFooter from '@components/templates/HeaderAndFooter'
import { GoBackConfig } from '@utils/goBackConfig'
import { BridgeService } from '@services/bridge'

import routes from './routes'
import Novo from './Novo'
import Contratacao from './ContratacaoCompleta'
import ModuloBiometria from './ModuloBiometria'

const Routes = () => (
  <>
    {BridgeService.isBrowser() ? (
      <GoogleReCaptchaProvider
        reCaptchaKey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
        useEnterprise
      >
        <BrowserRouter>
          <GoBackConfig />
          <HeaderAndFooter>
            <Switch>
              <Route path={routes.ROOT} component={Novo} />
            </Switch>
            <Switch>
              <Route path={routes.ROOT} component={Contratacao} />
            </Switch>
            <Switch>
              <Route path={routes.MODULO_BIOMETRIA} component={ModuloBiometria} />
            </Switch>
          </HeaderAndFooter>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    ) : (
      <BrowserRouter>
        <GoBackConfig />
        <Switch>
          <Route path={routes.ROOT} component={Novo} />
        </Switch>
        <Switch>
          <Route path={routes.ROOT} component={Contratacao} />
        </Switch>
        <Switch>
          <Route path={routes.MODULO_BIOMETRIA} component={ModuloBiometria} />
        </Switch>
      </BrowserRouter>
    )}
  </>
)

export default Routes
