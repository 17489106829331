import { Reducer } from 'redux'

import { VideoSelfieTypes, VideoSelfieState } from './types'

const INITIAL_STATE: VideoSelfieState = {
  unicoId: '',
  base64: '',
  encrypted: '',
  retryLiveness: false,
  error: false,
  loadingLiveness: false,
  lastLiveness: {
    unicoId: '',
    proposta: '',
    status: '',
    etapa: '',
    dataCriacao: '',
    score: 0,
  },
  biometricToken: '',
  loadingBiometrics: false,
}

const reducer: Reducer<VideoSelfieState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VideoSelfieTypes.SET_BASE64:
      return { ...state, base64: action.payload }
    case VideoSelfieTypes.SET_ENCRYPTED:
      return { ...state, encrypted: action.payload }
    case VideoSelfieTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case VideoSelfieTypes.SET_UNICO_ID:
      return { ...state, unicoId: action.payload }
    case VideoSelfieTypes.SET_LOADING_LIVENESS:
      return { ...state, loadingLiveness: action.payload }
    case VideoSelfieTypes.SET_RETRY_LIVENESS:
      return { ...state, retryLiveness: action.payload }
    case VideoSelfieTypes.SET_LAST_LIVENESS:
      return { ...state, lastLiveness: action.payload }
    case VideoSelfieTypes.SET_BIOMETRIC_TOKEN:
      return { ...state, biometricToken: action.payload }
    case VideoSelfieTypes.SET_LOADING_BIOMETRICS:
      return { ...state, loadingBiometrics: action.payload }
    case VideoSelfieTypes.RESET_LIVENESS:
      return { ...INITIAL_STATE }
    case VideoSelfieTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
