import { BaseServices, HttpMethod } from '@services/base'

export default (data: SalvarPropostaRequest) => {
  const pathname = process.env.REACT_APP_GET_CAPTURA_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const response = BaseServices.request<DataResponseType>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface SalvarPropostaRequest {
  clientId: string | number | null
  cpf: string
  escolaridade: string
  profissao: string
  valorPatrimonio: number
  pep: boolean
  tin: boolean
  numeroTin: string
  banco: string
  agencia: string
  conta: string
  paisNaturalidade: string
  estadoNaturalidade: string
  cidadeNaturalidade: string
  cep: string
  endereco: string
  numero: string
  complemento: string
  bairro: string
  estado: string
  cidade: string
  termoAceite: boolean
  numeroProposta: string
  tipoLiberacao: number
  ipCliente: string
  digitoConta: number
  simulacaoId: number
  codigoConvenio: number
}

export interface DataResponseType {
  documentoId: string
}
