import { Reducer } from 'redux'

import { DadosBancariosState, DadosBancariosTypes } from './types'

const INITIAL_STATE: DadosBancariosState = {
  bancos: [],
  bancosError: false,
  bancosLoading: false,

  bancoSelecionado: '',

  tipoContaSelecionado: 1,
  tipoCreditoInss: undefined,
  agencia: '',
  conta: '',
  digito: '',

  propostaEnviada: false,
  loading: false,
  error: false,
  dadosBancariosError: false,
  dadosBancariosLoading: false,

  contato: false,
  validaLoading: false,
}

const reducer: Reducer<DadosBancariosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DadosBancariosTypes.BANCOS_SUCCESS:
      return {
        ...state,
        bancosError: false,
        bancosLoading: false,
        bancos: action.payload,
      }

    case DadosBancariosTypes.BANCOS_REQUEST:
      return { ...state, bancosError: false, bancosLoading: true }
    case DadosBancariosTypes.BANCOS_LOADING:
      return { ...state, bancosError: false, bancosLoading: true }
    case DadosBancariosTypes.BANCOS_ERROR:
      return { ...state, bancosLoading: false, bancosError: true }
    case DadosBancariosTypes.SET_BANCO_SELECIONADO:
      return { ...state, bancoSelecionado: action.payload }
    case DadosBancariosTypes.SET_TIPO_CONTA:
      return { ...state, tipoContaSelecionado: action.payload }
    case DadosBancariosTypes.SET_TIPO_CREDITO_INSS:
      return { ...state, tipoCreditoInss: action.payload }
    case DadosBancariosTypes.SET_AGENCIA:
      return { ...state, agencia: action.payload }
    case DadosBancariosTypes.SET_CONTA:
      return { ...state, conta: action.payload }
    case DadosBancariosTypes.SET_DIGITO:
      return { ...state, digito: action.payload }

    case DadosBancariosTypes.SALVAR_PROPOSTA_REQUEST:
      return { ...state, loading: true, error: false, propostaEnviada: false }
    case DadosBancariosTypes.SALVAR_PROPOSTA_SUCCESS:
      return { ...state, loading: false, error: false, propostaEnviada: true }
    case DadosBancariosTypes.SALVAR_PROPOSTA_ERROR:
      return { ...state, loading: false, error: true }
    case DadosBancariosTypes.DADOS_BANCARIOS_INSS_SUCCESS:
      return {
        ...state,
        dadosBancariosError: false,
        dadosBancariosLoading: false,
      }
    case DadosBancariosTypes.DADOS_BANCARIOS_INSS_REQUEST:
      return {
        ...state,
        dadosBancariosError: false,
        dadosBancariosLoading: true,
      }
    case DadosBancariosTypes.DADOS_BANCARIOS_INSS_ERROR:
      return {
        ...state,
        dadosBancariosError: true,
        dadosBancariosLoading: false,
      }

    case DadosBancariosTypes.SET_CONTATO:
      return { ...state, contato: action.payload }

    case DadosBancariosTypes.SET_VALIDA_LOADING:
      return { ...state, validaLoading: action.payload }
    case DadosBancariosTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
