import styled from 'styled-components'

import { FontFamily } from '@interco/cp-react-ui-lib'
import { Colors } from '@utils/colors'

export const InputStyled = styled.input<{
  color: string
  error: boolean
}>`
  font-family: ${FontFamily.Sora};
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 4;
  color: ${Colors.GRAY500};
  font-size: 2em;
  display: flex;
  width: 100%;
  text-align: center;
  padding: 0.8rem;
  border-bottom: ${(props) =>
    props.error ? `2px solid ${Colors.ORANGE500}` : `2px solid ${props.color}`};
  @media (max-width: 600px) {
    font-size: 1.5rem;
    padding: 0.3rem;
  }
  @media (min-width: 601px) {
    font-size: 2rem;
    padding: 0.5rem;
  }
  &::placeholder {
    color: ${Colors.GRAY300};
    font-weight: normal;
  }
  &:focus {
    outline: none;
  }
`

export const Container = styled.div`
  width: 100%;
  margin: 24px 0;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
`

export const InputContainer = styled.div`
  margin: 8px;
  @media (max-width: 600px) {
    max-width: 9vw;
  }
  @media (min-width: 601px) {
    max-width: 8vw;
  }
  @media (min-width: 961px) {
    max-width: 6vw;
  }
  @media (min-width: 1281px) {
    max-width: 4vw;
  }
  @media (min-width: 1921px) {
    max-width: 3vw;
  }
`

export const Error = styled.div`
  border-bottom: 2px solid ${Colors.ERROR100};
`

export const ErrorInput = styled.input`
  color: ${Colors.ERROR500};
`
