import { action, Action } from 'typesafe-actions'

import {
  CallbackBiometria,
  CallbackBiometriaDados,
  CallbackLastLiveness,
  ILastLiveness,
  VideoSelfieTypes,
} from './types'

export const setBase64 = (payload: string): Action => action(VideoSelfieTypes.SET_BASE64, payload)

export const setEncrypted = (payload: string): Action =>
  action(VideoSelfieTypes.SET_ENCRYPTED, payload)

export const resetLiveness = (): Action => action(VideoSelfieTypes.RESET_LIVENESS)

export const setUnicoId = (payload: string): Action =>
  action(VideoSelfieTypes.SET_UNICO_ID, payload)

export const setLoadingLiveness = (payload: boolean): Action =>
  action(VideoSelfieTypes.SET_LOADING_LIVENESS, payload)

export const setError = (payload: boolean): Action => action(VideoSelfieTypes.SET_ERROR, payload)

export const getLastLivenessAction = (payload: CallbackLastLiveness): Action =>
  action(VideoSelfieTypes.GET_LAST_LIVENESS, payload)

export const postFotoBiometriaLiveness = (payload: CallbackBiometria): Action =>
  action(VideoSelfieTypes.POST_FOTO_BIOMETRIA_LIVENESS, payload)

export const setLastLiveness = (payload: ILastLiveness): Action =>
  action(VideoSelfieTypes.SET_LAST_LIVENESS, payload)

export const setRetryLiveness = (payload: boolean): Action =>
  action(VideoSelfieTypes.SET_RETRY_LIVENESS, payload)

export const setBiometricToken = (payload: string): Action =>
  action(VideoSelfieTypes.SET_BIOMETRIC_TOKEN, payload)

export const getBiometriaDados = (payload: CallbackBiometriaDados): Action =>
  action(VideoSelfieTypes.GET_BIOMETRIA_DADOS, payload)

export const setLoadingBiometrics = (payload: boolean): Action =>
  action(VideoSelfieTypes.SET_LOADING_BIOMETRICS, payload)

export const postValidateBiometrics = (payload: CallbackBiometriaDados): Action =>
  action(VideoSelfieTypes.POST_VALIDATE_BIOMETRICS, payload)
