import { BaseServices, HttpMethod } from '@services/base'

export interface DataCreatePropostaType {
  simulacaoId: number
}

export interface CreatePropostaRequest {
  codigo: string
}

export default async (data: DataCreatePropostaType, newSimulatorApi: boolean) => {
  const pathname = newSimulatorApi
    ? process.env.REACT_APP_CRIAR_PROPOSTA_BFF
    : process.env.REACT_APP_CRIAR_PROPOSTA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const response = await BaseServices.request<CreatePropostaRequest>({
    pathname,
    method,
    host,
    data,
  })
  return response
}
