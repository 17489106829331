import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { criarProposta, criarPropostaError, criarPropostaSuccess } from '../actions'
import { CriacaoPropostaPayload } from '../types'

export default function* postCriarProposta(action: AnyAction) {
  const payload = action.payload as CriacaoPropostaPayload
  const { idSimulacao } = yield* select((state: ApplicationState) => state.simulacao)
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
  try {
    const { data } = yield* call(
      services.postProposta,
      {
        simulacaoId: Number(idSimulacao),
      },
      newSimulatorApi,
    )
    yield put(criarPropostaSuccess(data.codigo))
    if (typeof payload?.callback === 'function') {
      yield payload.callback()
    }
  } catch (error) {
    yield put(criarPropostaError())
    yield put(
      showError({
        title: 'Erro ao criar proposta',
        message: 'Ocorreu um erro ao criar proposta',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_004_CRIAR,
        actionCallback: () => criarProposta(payload),
      }),
    )
  }
}
