import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import {
  escolaridadesError,
  escolaridadesLoading,
  escolaridadesRequest,
  escolaridadesSuccess,
} from '../actions'
import { Escolaridade, EscolaridadesAPI } from '../types'

const escolaridadesBuild = (data: EscolaridadesAPI): Escolaridade[] =>
  data &&
  data?.escolaridades.map((escolaridade) => ({
    codigo: escolaridade.codigo,
    descricao: escolaridade.descricao,
  }))

export default function* getEscolaridades() {
  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    yield put(escolaridadesLoading())
    const response = yield* call(services.getEscolaridades, newSimulatorApi)
    yield put(escolaridadesSuccess(escolaridadesBuild(response.data)))
  } catch (error) {
    yield put(escolaridadesError())
    yield put(
      showError({
        title: 'Erro ao buscar as escolaridades',
        message: 'Ocorreu um erro na listagem de escolaridades.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_001_ESCOLARIDADES,
        actionCallback: () => escolaridadesRequest(),
      }),
    )
  }
}
