import { AnyAction } from 'redux'
import { select, call, put } from 'typed-redux-saga'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import postDocumentoAssincrono from '@services/postDocumentoAssincrono'
import putAtualizarStatusAssinatura from '@services/putAtualizarStatusAssinatura'

import { sendDocumentoAssincrono, setLoading } from '../actions'
import { SendDocumentoAssincronoPayload } from '../types'

export default function* postDocumentoAssincronoSaga(action: AnyAction) {
  const payload = action.payload as SendDocumentoAssincronoPayload

  try {
    yield put(setLoading(true))

    const { assinaturas, counterDocsParaAssinar } = yield* select(
      (state: ApplicationState) => state.assinatura.documentosAssinatura,
    )
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    if (counterDocsParaAssinar === assinaturas?.length) {
      const { status } = yield* call(
        postDocumentoAssincrono,
        { assinaturas, anexarFB: false },
        newSimulatorApi,
      )
      if (status === 200 || status === 202) {
        const { status: statusAtualizar } = yield* call(
          putAtualizarStatusAssinatura,
          {
            assinaturas,
          },
          newSimulatorApi,
        )
        if (statusAtualizar === 200 || statusAtualizar === 202) {
          yield payload.success()
          yield put(setLoading(false))
        } else {
          yield put(setLoading(false))
          yield put(
            showError({
              title: 'Erro ao enviar documentos',
              message: 'Ocorreu um erro ao atualizar status da(s) assinatura(s)',
              actionTitle: 'Tentar Novamente',
              code: ErrorCodes.A0_002_ENVIAR_ASSINCRONO,
              actionCallback: () => sendDocumentoAssincrono(payload),
            }),
          )
        }
      } else {
        yield put(setLoading(false))
        yield put(
          showError({
            title: 'Erro ao enviar documentos',
            message: 'Ocorreu um erro ao enviar os documentos.',
            actionTitle: 'Tentar Novamente',
            code: 'ERR_001',
            actionCallback: () => sendDocumentoAssincrono(payload),
          }),
        )
      }
    } else {
      yield put(setLoading(false))
      yield put(
        showError({
          title: 'Erro ao enviar documentos',
          message: 'Ocorreu um erro ao enviar os documentos.',
          actionTitle: 'Tentar Novamente',
          code: 'ERR_002',
          actionCallback: () => sendDocumentoAssincrono(payload),
        }),
      )
    }
  } catch (error) {
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao enviar documentos',
        message: 'Ocorreu um erro ao enviar os documentos.',
        actionTitle: 'Tentar Novamente',
        code: 'ERR_003',
        actionCallback: () => sendDocumentoAssincrono(payload),
      }),
    )
  }
}
