import { all } from 'typed-redux-saga'

import watchCaptura from './captura/saga'
import watchGlobalApi from './globalApi/saga'
import watchAssinatura from './assinatura/saga'
import watchProposta from './proposta/saga'
import watchToken from './token/saga'
import watchIndicacao from './indicacaoProposta/saga'

export default function* rootSaga() {
  yield* all([
    watchCaptura(),
    watchAssinatura(),
    watchGlobalApi(),
    watchProposta(),
    watchToken(),
    watchIndicacao(),
  ])
}
