/**
 * Actions Types
 */

export enum ContrachequeDocumentosTypes {
  SET_INPUT_FILES_CONTRACHEQUE = '@captura/contrachequeDocumentos/SET_INPUT_FILES_CONTRACHEQUE',
  SET_ANEXOS_CONTRACHEQUE = '@captura/contrachequeDocumentos/SET_ANEXOS_CONTRACHEQUE',
  DELETE_ANEXO_CONTRACHEQUE = '@captura/contrachequeDocumentos/DELETE_ANEXO_CONTRACHEQUE',
  SET_IS_DONE_CONTRACHEQUE = '@captura/contrachequeDocumentos/SET_IS_DONE_CONTRACHEQUE',
  SET_IS_LOADING_CONTRACHEQUE = '@captura/contrachequeDocumentos/SET_IS_LOADING_CONTRACHEQUE',
  SET_IS_ERROR_CONTRACHEQUE = '@captura/contrachequeDocumentos/SET_IS_ERROR_CONTRACHEQUE',
  SEND_TO_S3_PAYSLIP_SAGA = '@captura/contrachequeDocumentos/SEND_TO_S3_PAYSLIP_SAGA',
  SET_ROLLBACK_ROUTER_ERROR_CONTRACHEQUE = '@captura/contrachequeDocumentos/SET_ROLLBACK_ROUTER_ERROR_CONTRACHEQUE',
  RESET = 'RESET',
}

export interface BlobsType {
  height: number
  width: number
  data: string
}

export interface Anexo {
  anexo: {
    id: string
    nome: string
    tipoExtensao: string
    size?: number
    blobs: BlobsType[]
  }
  tipo: string
}

export interface S3Type {
  tipo: string
  anexos: Anexo[]
  callback?: () => void
}
/**
 * State Type
 */

export interface ContrachequeDocumentosState {
  readonly inputFiles: string[]
  readonly anexos: Anexo[]
  readonly isDone: boolean
  readonly isLoading: boolean
  readonly isError: boolean
  readonly rollbackRouterError: string
  readonly indice: number
}
