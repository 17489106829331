import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { hideLoading, showLoading } from '@store/captura/documentos/actions'
import { showError } from '@store/ui/error/actions'

import { dadosResumoError, dadosResumoRequest, dadosResumoSucess } from '../actions'

interface Props {
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
    block: boolean
  }
  type: string
}

export default function* getResumoDadosSaga({
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => dadosResumoRequest(),
    actionTitle: 'Tentar Novamente',
    block: true,
  },
}: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  try {
    yield put(showLoading())
    const { data } = yield services.postDadosResumo(
      {
        numeroProposta: String(appState.proposta.criacaoProposta.numeroProposta),
        cpf: appState.simulacao.cpf,
        email: appState.assinatura.dadosToken.email || appState.captura.documentos.email,
        telefone: appState.captura.documentos.telefone,
      },
      appState.ui.navigation.isApp,
    )

    yield put(dadosResumoSucess(data))
    yield put(hideLoading())
    return true
  } catch (error) {
    yield put(showError(actionCallbackError))
    yield put(hideLoading())
    yield put(dadosResumoError())
    return false
  }
}
