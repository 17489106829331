import { Reducer } from 'redux'

import { Anexo, Base64DocType, FotoDocumentosState, FotoDocumentosTypes } from './types'

const INITIAL_STATE: FotoDocumentosState = {
  inputFiles: [],
  anexos: [],
  isDone: false,
  isLoading: false,
  isError: false,
  rollbackRouterError: '',
  indice: 0,

  solicitaPermissoes: false,
  base64Documentos: [],
  errorDocumentos: '',
  retryDocumento: false,
}

const deleteAnexo = (anexosAtual: Anexo[], indice: number) => {
  const novaListaAnexos = anexosAtual || []
  novaListaAnexos.splice(indice, 1)
  return novaListaAnexos
}

const addAnexo = (anexosAtual: Anexo[], anexoNovo: Anexo) => {
  const anexos = anexosAtual || []
  anexos.push(anexoNovo)
  return anexos
}

const addAnexoUnico = (anexosAtual: Base64DocType[], anexoNovo: Base64DocType) => {
  const anexos = anexosAtual || []
  anexos.push(anexoNovo)
  return anexos
}

const reducer: Reducer<FotoDocumentosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FotoDocumentosTypes.SET_INPUT_FILES:
      return { ...state, inputFiles: action.payload }
    case FotoDocumentosTypes.SET_ANEXOS:
      return { ...state, anexos: addAnexo(state.anexos, action.payload) }
    case FotoDocumentosTypes.DELETE_ANEXO:
      return {
        ...state,
        anexos: deleteAnexo(state.anexos, action.payload),
      }
    case FotoDocumentosTypes.SET_IS_DONE:
      return { ...state, isDone: action.payload }
    case FotoDocumentosTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case FotoDocumentosTypes.SET_IS_ERROR:
      return { ...state, isError: action.payload }
    case FotoDocumentosTypes.SET_ROLLBACK_ROUTER_ERROR:
      return { ...state, rollbackRouterError: action.payload }
    case FotoDocumentosTypes.RESET:
      return { ...INITIAL_STATE }
    case FotoDocumentosTypes.SOLICITA_PERMISSOES:
      return { ...state, solicitaPermissoes: true }
    case FotoDocumentosTypes.RESET_FOTO_DOCUMENTO:
      return {
        ...state,
        base64Documentos: [],
        errorDocumentos: '',
        retryDocumento: false,
        loadingLiveness: false,
      }
    case FotoDocumentosTypes.SET_BASE64_DOCUMENTOS:
      return { ...state, base64Documentos: addAnexoUnico(state.base64Documentos, action.payload) }
    case FotoDocumentosTypes.SET_RETRY_DOCUMENTO:
      return { ...state, retryDocumento: action.payload }
    case FotoDocumentosTypes.SET_ERROR_DOCUMENTO:
      return { ...state, errorDocumentos: action.payload }
    default:
      return state
  }
}

export default reducer
