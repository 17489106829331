import { BaseServices, HttpMethod } from '@services/base'

export default (data: EnviaCodigoApiRequest, captcha: string) => {
  const pathname = `${process.env.REACT_APP_ENVIO_CODIGO}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers = { captcha }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface EnviaCodigoApiRequest {
  nome: string
  cpf: string
  email: string
  celular: string
  envio: string[]
}
