import { action, Action } from 'typesafe-actions'

import { DadosTokenTypes, RealizarAverbacaoSiapePayload } from './types'

export const setTelefone = (payload: string): Action =>
  action(DadosTokenTypes.SET_TELEFONE, payload)
export const setEmailToken = (payload: string): Action => action(DadosTokenTypes.SET_EMAIL, payload)

export const realizarAverbacaoSiapeRequest = (payload: RealizarAverbacaoSiapePayload): Action =>
  action(DadosTokenTypes.REALIZAR_AVERBACAO_SIAPE_REQUEST, payload)

export const setAverbacaoAutorizada = (payload: boolean): Action =>
  action(DadosTokenTypes.SET_AVERBACAO_AUTORIZADA, payload)
