import MobileBridge from '@interco/inter-mobile-bridge'

import { TParamsRequest } from './types'

declare global {
  interface Window {
    webkit: {
      messageHandlers: { execute: unknown }
    }
    Android: { execute: unknown }
  }
}

export const isOldBridge = () => !(window.Android || window.webkit?.messageHandlers)?.execute

export const callByOldBridge = async ({
  headers = { 'Content-Type': 'application/json' },
  pathname,
  method,
  data,
  host,
  engine,
}: TParamsRequest) => {
  const path = `/mobile/${pathname}`
  const response = await MobileBridge.execute({
    headers,
    pathname: path,
    method,
    data,
    host,
    engine: engine || 'CA',
  })
  return response
}
