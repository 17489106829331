import { BaseServices, HttpMethod } from '@services/base'

export interface RiscoEnderecoType {
  idConvenio: number
  estado: string
  cidade: string
  newSimulatorApi: boolean
}

export default async (payload: RiscoEnderecoType) => {
  const pathname = payload.newSimulatorApi
    ? process.env.REACT_APP_RISCO_ENDERECO_BFF
    : process.env.REACT_APP_RISCO_ENDERECO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST

  const data = {
    idConvenio: payload.idConvenio,
    endereco: {
      estado: payload.estado,
      cidade: payload.cidade,
    },
  }

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}
