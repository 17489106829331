import axios from 'axios'

import { HttpMethod } from '@services/base'

export default class FilesService {
  static async sendFileToS3(data: File | Blob, url: string) {
    const headers = {
      'Content-Type': data.type,
    }

    return axios({
      headers,
      url,
      method: HttpMethod.PUT,
      data,
    })
  }
}
