/**
 * Adiciona mascara de data sobre o valor passado
 * @param value String
 * @returns 00/00/0000
 */
export const dataMask = (value: string) => {
  let p = String(value)
  if (p) {
    p = p.replace(/\D/g, '') // Remove tudo o que não é dígito
    p = p.replace(/(\d{2})(\d)/, '$1/$2')
    p = p.replace(/(\d{2})(\d)/, '$1/$2')
    p = p.replace(/(\d{4})(\d)/, '$1')
  }
  return p
}

/**
 * Adiciona mascara de cep sobre o valor passado
 * @param value String
 * @returns 00.000-000
 */

export const cepMask = (value: string) => {
  let p = String(value)
  if (p) {
    p = p.replace(/\D/g, '') // Remove tudo o que não é dígito
    p = p.replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto entre o segundo e o terceiro dígitos
    p = p.replace(/\.(\d{3})(\d)/, '.$1-$2') // Coloca hífen entre o quinto e o sexto dígitos
  }
  return p
}

/** Adiciona mascara de telefone sobre o valor passado
 * @param value String
 * @returns (00) 00000-0000
 */
export const phoneMask = (value: string) => {
  let p = String(value)
  if (p) {
    p = p.replace(/\D/g, '') // Remove tudo o que não é dígito
    p = p.replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
    p = p.replace(/(\d{5}|\d{4})(\d{4})$/, '$1-$2') // Coloca hífen entre o quarto e o quinto dígitos
  }
  return p
}

/**
 * Remove mascaras diversas, deixando apenas digitos
 * @param value String
 * @returns (00) 00000-0000
 */
export const removeMask = (value: string) => value.replace(/\D/g, '')

/**
 * Função para ocultar o email no envio de token
 * @param value String
 */
export const ocultarEmail = (fullEmail: string) => {
  if (!fullEmail) return '****'
  const [email, domain] = fullEmail.split('@')
  return `${email.substr(0, Math.ceil(email.length * 0.4))}*****@${domain}`
}

/**
 * Converte um number para string de moeda(Real) sem cifrão
 * @param value Number
 * @returns 999,99
 */
export const formatCurrencySemCifrao = (value: number | string | undefined) =>
  !value
    ? '0,00'
    : value.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

/**
 * Adiciona máscara para agencia
 * @param value String
 */
export const agenciaMask = (value: string) => {
  value = value.replace(/\D/g, '') // Remove tudo o que não é dígito
  const valueRemoveZeros = value.replace(/^0+/, '') // Remove os zeros a esquerda
  let valueReturn = ''
  if (valueRemoveZeros) {
    valueReturn = `0000${valueRemoveZeros.toString()}`.slice(-4)
  }
  return valueReturn
}

/**
 * Retorna o valor convertido de uma string para number
 * @param value String
 * @returns  999.99
 */
export const currencyToFloat = (value: string) => {
  if (value) {
    const numValue = Number(value.replace(/[^\d]+/g, '')) / 100
    return parseFloat(numValue.toFixed(2))
  }
  return 0
}

/**
 * Converte um number para string de moeda(Real)
 * @param value Number
 * @returns R$ 999,99
 */
export const formatCurrency = (value: number | string | undefined) =>
  !value
    ? ''
    : value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      })

/**
 * Converte um valor monetário que esta em string ex: 1.250,45
 * para um valor float ex: 1250.45
 * @param valueString String
 * @returns ex: 1250.45
 */
export const convertStringValueToNumber = (valueString: string) => {
  const removedDots = valueString.replace('.', '')
  const replacedCommas = removedDots.replace(',', '.')
  return parseFloat(replacedCommas)
}
