/**
 * Actions Types
 */

export enum CriacaoPropostaTypes {
  SET_NOME = '@proposta/criacao/SET_NOME',
  SET_NUMERO_PROPOSTA = '@proposta/criacao/SET_NUMERO_PROPOSTA',
  SET_DATA_CRIADA = '@proposta/criacao/SET_DATA_CRIADA',

  SET_ID_ITERACAO = '@proposta/criacao/SET_ID_ITERACAO',
  SET_TIPO_DOCUMENTO = '@proposta/criacao/SET_TIPO_DOCUMENTO',
  SET_DOCUMENTO_ID = '@proposta/criacao/SET_DOCUMENTO_ID',

  CRIAR_PROPOSTA = '@proposta/criacao/CRIAR_PROPOSTA',
  CRIAR_PROPOSTA_SUCCESS = '@proposta/criacao/CRIAR_PROPOSTA_SUCCESS',
  CRIAR_PROPOSTA_ERROR = '@proposta/criacao/CRIAR_PROPOSTA_ERROR',

  TRACK_NEW_RELIC = '@proposta/criacao/TRACK_NEW_RELIC',
  RESET = 'RESET',
}

export interface DocumentosEnvioType {
  idDocumento?: string
  idTipoDocumento: string
  nomeTipoDocumento?: string
  status?: string
}

export interface CriacaoPropostaPayload {
  callback?: () => void
}
/**
 * State Type
 */

export interface CriacaoPropostaState {
  readonly nome: string
  readonly dataCriada: string
  readonly numeroProposta: string | number | undefined
  readonly loading: boolean
  readonly error: boolean

  readonly tipoDocumento: DocumentosEnvioType[] | undefined
  readonly idIteracao: string
  readonly documentoId: string
}
