import { action, Action } from 'typesafe-actions'

import { Anexo, Base64DocType, FotoDocumentosTypes, S3Type } from './types'

export const setInputFiles = (payload: string | number | undefined): Action =>
  action(FotoDocumentosTypes.SET_INPUT_FILES, payload)

export const setAnexos = (payload: Anexo): Action => action(FotoDocumentosTypes.SET_ANEXOS, payload)

export const deleteAnexo = (payload: number): Action =>
  action(FotoDocumentosTypes.DELETE_ANEXO, payload)

export const setIsDone = (payload: boolean): Action =>
  action(FotoDocumentosTypes.SET_IS_DONE, payload)

export const setIsLoading = (payload: boolean): Action =>
  action(FotoDocumentosTypes.SET_IS_LOADING, payload)

export const setIsError = (payload: boolean): Action =>
  action(FotoDocumentosTypes.SET_IS_ERROR, payload)

export const sendToS3 = (payload: S3Type): Action =>
  action(FotoDocumentosTypes.SEND_TO_S3_SAGA, payload)

export const setRollbackRouterError = (payload: string | number | undefined): Action =>
  action(FotoDocumentosTypes.SET_ROLLBACK_ROUTER_ERROR, payload)

export const callSolicitaPermissoes = () => action(FotoDocumentosTypes.SOLICITA_PERMISSOES)

export const setErrorDocumento = (payload: boolean): Action =>
  action(FotoDocumentosTypes.SET_ERROR_DOCUMENTO, payload)

export const setBase64Documentos = (payload: Base64DocType): Action =>
  action(FotoDocumentosTypes.SET_BASE64_DOCUMENTOS, payload)

export const setRetryDocumento = (payload: boolean): Action =>
  action(FotoDocumentosTypes.SET_RETRY_DOCUMENTO, payload)

export const resetFotoDocumento = (payload: boolean): Action =>
  action(FotoDocumentosTypes.RESET_FOTO_DOCUMENTO, payload)

export const postFotoDocumentoLiveness = (payload: FotoPayload): Action =>
  action(FotoDocumentosTypes.POST_FOTO_DOCUMENTOS_LIVENESS, payload)

export interface FotoPayload {
  base64array: string[]
  base64Documentos: Base64DocType[]
  callback: () => void
}
