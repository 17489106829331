/**
 * Actions Types
 */

export enum DadosTokenTypes {
  SET_TELEFONE = '@assinatura/dadosToken/SET_TELEFONE',
  SET_EMAIL = '@assinatura/dadosToken/SET_EMAIL',
  RESET = 'RESET',
  REALIZAR_AVERBACAO_SIAPE_REQUEST = '@assinatura/dadosToken/REALIZAR_AVERBACAO_SIAPE_REQUEST',
  SET_AVERBACAO_AUTORIZADA = '@assinatura/dadosToken/SET_AVERBACAO_AUTORIZADA',
}

/**
 * State Type
 */

export interface DadosTokenState {
  readonly telefone: string
  readonly email: string
  readonly averbacaoAutorizada: boolean
}

/**
 * Action Payload Types
 */

export interface RealizarAverbacaoSiapePayload {
  success: () => void
}
