/**
 * Actions Types
 */

export enum FotoDocumentosTypes {
  SET_INPUT_FILES = '@captura/fotoDocumentos/SET_INPUT_FILES',
  SET_ANEXOS = '@captura/fotoDocumentos/SET_ANEXOS',
  DELETE_ANEXO = '@captura/fotoDocumentos/DELETE_ANEXO',
  SET_IS_DONE = '@captura/fotoDocumentos/SET_IS_DONE',
  SET_IS_LOADING = '@captura/fotoDocumentos/SET_IS_LOADING',
  SET_IS_ERROR = '@captura/fotoDocumentos/SET_IS_ERROR',
  SEND_TO_S3_SAGA = '@captura/fotoDocumentos/SEND_TO_S3_SAGA',

  SET_ROLLBACK_ROUTER_ERROR = '@captura/fotoDocumentos/SET_ROLLBACK_ROUTER_ERROR',
  RESET = 'RESET',

  SOLICITA_PERMISSOES = '@captura/fotoDocumentos/SOLICITA_PERMISSOES',

  SET_RETRY_DOCUMENTO = '@captura/fotoDocumentos/SET_RETRY_DOCUMENTO',
  SET_BASE64_DOCUMENTOS = '@captura/fotoDocumentos/SET_BASE64_DOCUMENTOS',
  RESET_FOTO_DOCUMENTO = '@captura/fotoDocumentos/RESET_FOTO_DOCUMENTO',
  SET_ERROR_DOCUMENTO = '@captura/fotoDocumentos/SET_ERROR_DOCUMENTO',
  POST_FOTO_DOCUMENTOS_LIVENESS = '@captura/fotoDocumentos/POST_FOTO_DOCUMENTOS_LIVENESS',
}

export interface BlobsType {
  height: number
  width: number
  data: string
}

export interface Anexo {
  anexo: {
    id: string
    nome: string
    tipoExtensao: string
    size?: number
    blobs: BlobsType[]
  }
  tipo: string
}

export interface S3Type {
  tipo: string
  anexos: Anexo[]
  callback?: () => void
}

export interface Base64DocType {
  base64: string
  encrypted: string
  type: string
}

/**
 * State Type
 */

export interface FotoDocumentosState {
  readonly inputFiles: string[]
  readonly anexos: Anexo[]
  readonly isDone: boolean
  readonly isLoading: boolean
  readonly isError: boolean
  readonly rollbackRouterError: string
  readonly indice: number
  readonly solicitaPermissoes: boolean

  readonly base64Documentos: Base64DocType[]
  readonly errorDocumentos: string
  readonly retryDocumento: boolean
}
