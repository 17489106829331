import { TServiceResponseError } from './types'

export class ServiceResponseError extends Error {
  message: string

  data: unknown

  status?: number

  constructor({ message, data, status }: TServiceResponseError) {
    super(message)

    this.message = message
    this.data = data
    this.status = status
  }
}
