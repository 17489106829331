import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { ErrorCodes } from '@utils/enums'
import { showError } from '@store/ui/error/actions'
import { setLista } from '@store/assinatura/listaDocumentos/actions'
import { TipoDocs } from '@store/assinatura/listaDocumentos/types'
import postAssinarDocumentos from '@services/postAssinarDocumentos'

import { setAssinaturas } from '../actions'

interface Props {
  tipo: TipoDocs
  type: string
  email?: string
  telefone?: string
  success: (retorno: boolean) => void
}

export default function* sendDocumentosAssinaturaSaga(action: AnyAction) {
  const payload = action.payload as Props
  const { tipo, success } = payload

  let listaDocumentos = yield* select((state: ApplicationState) => state.assinatura.listaDocumentos)
  const { origem } = yield* select((state: ApplicationState) => state.ui.navigation)
  const { numeroProposta } = yield* select(
    (state: ApplicationState) => state.proposta.criacaoProposta,
  )
  const { assinaturas, counterDocsParaAssinar } = yield* select(
    (state: ApplicationState) => state.assinatura.documentosAssinatura,
  )
  const { email, telefone } = yield* select((state: ApplicationState) => state.captura.documentos)
  const { email: emailToken } = yield* select(
    (state: ApplicationState) => state.assinatura.dadosToken,
  )
  const { telefoneCelular } = yield* select(
    (state: ApplicationState) => state.assinatura.resumo.resumo,
  )
  const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)

  try {
    yield put(
      setLista({
        ...listaDocumentos,
        [tipo]: {
          ...listaDocumentos[tipo],
          isLoading: true,
          isError: false,
        },
      }),
    )

    const payloadAssinatura = {
      documento: tipo,
      origem,
      fluxo: 'ASSINATURA_DIGITAL_CAPTURA_COMPLETA',
      proposta: numeroProposta ? String(numeroProposta) : '',
      email: email || emailToken || payload.email,
      telefone: telefone || telefoneCelular || payload.telefone,
    }

    const {
      data: { assinatura },
    } = yield* call(postAssinarDocumentos, payloadAssinatura, newSimulatorApi)

    yield put(setAssinaturas(assinatura))

    listaDocumentos = yield* select((state: ApplicationState) => state.assinatura.listaDocumentos)
    yield put(
      setLista({
        ...listaDocumentos,
        [tipo]: {
          ...listaDocumentos[tipo],
          isLoading: false,
          isDone: true,
          isError: false,
        },
      }),
    )
    if (assinaturas.length === counterDocsParaAssinar) {
      yield success(true)
    } else {
      yield success(false)
    }
  } catch (error) {
    listaDocumentos = yield* select((state: ApplicationState) => state.assinatura.listaDocumentos)
    yield put(
      setLista({
        ...listaDocumentos,
        [tipo]: {
          ...listaDocumentos[tipo],
          isLoading: false,
          isDone: false,
          isError: true,
        },
      }),
    )
    yield put(
      showError({
        title: 'Erro ao salvar o documento',
        message: 'Por favor tente novamente.',
        code: ErrorCodes.A0_001_ENVIAR_ASSINATURA,
      }),
    )
  }
}
