import { Reducer } from 'redux'

import { AverbacaoExercitoTypes, AverbacaoExercitoState } from './types'

const INITIAL_STATE: AverbacaoExercitoState = {
  senhaAverbacao: '',
  averbacaoValida: true,
  tentativas: 0,
  loading: false,
}

const reducer: Reducer<AverbacaoExercitoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AverbacaoExercitoTypes.SET_AVERBACAO_VALIDA:
      return { ...state, averbacaoValida: action.payload }
    case AverbacaoExercitoTypes.SET_TENTATIVAS:
      return { ...state, tentativas: action.payload }
    case AverbacaoExercitoTypes.SET_SENHA_AVERBACAO:
      return { ...state, senhaAverbacao: action.payload }
    case AverbacaoExercitoTypes.SET_LOADING:
      return { ...state, loading: action.payload }
    case AverbacaoExercitoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
